<template>
<div class="row">
    <div class="col-lg-12">
        <ChangeSource currentType="url" v-on:change-source="setSource" />

        <div class="select-file">
            <p class="text-left pb-4">{{ $t('wizard-authorized-ip') }}</p>
            <div class="form-group row">
                <div for="url" class="col-sm-2 text-left">{{ $t('url') }}</div>
                <div class="col-sm-10">
                    <input type="text" id="url" v-model="details.url" :placeholder="$t('url-placeholder')" class="form-control">
                </div>
            </div>
            

        </div>
    </div>
</div>
</template>
<script>
import ChangeSource from '@/components/Wizards/sources/ChangeSource.vue';

 export default {
    name: 'source-url',
    data() {
        return {
            details: {
                url: ''
            }
        };
    },
    components: {
        ChangeSource
    },
    methods: {
        setSource(source) {
            this.$emit('change-source', source);
        },
        analyze() {
            this.$emit('upload', this.details);
        }
    }
};
</script>
