a
<template>
  <div>

    <Header />

    <div class="row no_side_margin content">

      <Sidebar />

      <div class="col-md-9 col-lg-10 no_side_pad" v-bind:class="{ collapse_active_footer: isActive }">

        <button @click="menuCollapse" class="collapse-btn">{{isActiveIcon}}</button>




        <!-- settings content start start -->

        <div class="row no_side_margin">
          <div class="total-setting col-lg-12 ">
            <ul class="nav nav-tabs setting-tabs">
              <li class="nav-item"><a data-toggle="tab" href="#home" class="nav-link" ref="homeclick"
                  v-on:click="setSection('profile')" v-bind:class="{ active: section === 'profile' }">
                  <font-awesome-icon icon="user" size="lg" />
                  {{$t('profile')}}</a>
              </li>

              <li class="nav-item"><a data-toggle="tab" href="#menu1" class="nav-link"
                  v-on:click="setSection('billing')"
                  v-bind:class="{ active: section === 'billing' || section === 'invoice' }">
                  <font-awesome-icon icon="file-invoice-dollar" size="lg" />
                  {{$t('billing')}}</a></li>


              <li class="nav-item ">
                <a data-toggle="tab" href="#menu5" class="nav-link" v-on:click="setSection('affiliate')"
                  v-bind:class="{ active: section === 'affiliate' }">
                  <font-awesome-icon width="24" height="24" :icon="['fas', 'gift']" size="lg" />
                  {{$t('invite')}}</a>
              </li>

              <li class="nav-item"><a data-toggle="tab" href="#menu3" v-on:click="setSection('integration')"
                  class="nav-link " v-bind:class="{ active: section === 'integration' }">
                  <font-awesome-icon width="24" height="24" :icon="['fas', 'code']" size="lg" />
                  {{$t('integration')}}</a>
              </li>

              <!--li class="nav-item "><a data-toggle="tab" href="#menu4" v-on:click="setSection('agreements')" class="nav-link "  v-bind:class="{ active: section === 'agreements' }">
                <font-awesome-icon width="24" height="24" :icon="['fas', 'handshake']" size="lg"/>
                {{$t('agreement')}}</a>
            </li-->


            </ul>

            <div class="tab-content">
              <div id="home" class="tab-pane container" v-bind:class="{ active: section === 'profile', fade: section !== 'profile'  }">
                <div class="row no_side_margin" v-if="this.user">
                  <div class="col-lg-9">
                    <div class="sub-tab-content tab-content" style="padding-top: 0px;">
                      <div id="sub-menu1" class="container tab-pane" v-bind:class="{ active: profile_section === 'data', fade: profile_section !== 'data'  }">
                        <div class="row">
                          <div class="col-lg-12 total-bg no_side_pad">
                            <h4>{{$t('data')}}</h4>
                            <div class="profile-settings" v-if="user">
                              <div class="fileld">
                                <div class="row">
                                  <label class="col-3">{{$t('fullname')}}</label>
                                  <div class="col-9">
                                    <input type="text" class="form-control" id="name" v-model="$v.user.name.$model"
                                      :placeholder="$t('placeholder-name')" v-on:keyup="hasToBeSaved()"
                                      v-on:click="$event.target.select()" required>
                                    <div class="error text-left" v-if="!$v.user.name.required">{{$t('field-required')}}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="fileld" v-if="user.company">
                                <div class="row">
                                  <label class="col-3">{{$t('company_individual')}}</label>
                                  <div class="col-9">
                                    <input type="text" class="form-control" id="company"
                                      v-model="$v.user.company.name.$model" :placeholder="$t('placeholder-company')"
                                      v-on:click="$event.target.select()" v-on:keyup="hasToBeSaved()" required>
                                    <div class="error text-left" v-if="!$v.user.company.name.required">
                                      {{$t('field-required')}}</div>
                                    <small class="help">{{$t('company_individual_help')}}</small>
                                  </div>
                                </div>
                              </div>

                              <div class="fileld" v-if="user.company">
                                <div class="row">
                                  <label class="col-3">{{$t('email')}}</label>
                                  <div class="col-9"><input type="email" disabled="true" class="form-control"
                                      id="email" name="email" v-model='user.email'
                                      :placeholder="$t('placeholder-email')" v-on:click="$event.target.select()"
                                      v-on:keyup="hasToBeSaved()"></div>
                                </div>
                              </div>

                              <div class="fileld" v-if="user.company">
                                <div class="row">
                                  <label class="col-3 control-label" for="company_cif">{{$t('nif')}}</label>
                                  <div class="col-9">
                                    <input type="text" class="form-control" id="company_cif" v-model='user.company.cif'
                                      :placeholder="$t('placeholder-nif')" v-on:click="$event.target.select()"
                                      v-on:keyup="hasToBeSaved()" required>
                                    <small class="help" v-if="!user.company.vat_exempt">{{$t('nif_help')}}</small>
                                    <small class="text-success"
                                      v-if="user.company.vat_exempt">{{$t('nif_verified')}}</small>
                                    <div class="error text-left" v-if="!$v.user.company.cif.required">
                                      {{$t('field-required')}}</div>
                                  </div>
                                </div>
                              </div>

                              <div class="fileld" v-if="user.company">
                                <div class="row">
                                  <label class="col-3">{{$t('phone')}}</label>
                                  <div class="col-9">
                                    <input type="text" class="form-control" id="company_phone"
                                      v-model='$v.user.company.phone.$model' :placeholder="$t('placeholder-phone')"
                                      v-on:click="$event.target.select()" v-on:keyup="hasToBeSaved()" required>
                                    <div class="error text-left" v-if="!$v.user.company.phone.required">
                                      {{$t('field-required')}}</div>
                                  </div>
                                </div>
                              </div>

                              <div class="fileld" v-if="user.company">
                                <div class="row">
                                  <label class="col-3">{{$t('address')}}</label>
                                  <div class="col-9">
                                    <input type="text" class="form-control" id="company_address"
                                      v-model='$v.user.company.address.$model' :placeholder="$t('placeholder-address')"
                                      v-on:click="$event.target.select()" v-on:keyup="hasToBeSaved()" required>
                                    <div class="error text-left" v-if="!$v.user.company.address.required">
                                      {{$t('field-required')}}</div>
                                  </div>
                                </div>
                              </div>

                              <div class="fileld" v-if="user.company">
                                <div class="row">
                                  <label class="col-3">{{$t('zipcode')}}</label>
                                  <div class="col-9">
                                    <input type="text" class="form-control" id="company_zipcode"
                                      v-model='$v.user.company.zipcode.$model' :placeholder="$t('placeholder-zipcode')"
                                      v-on:click="$event.target.select()" v-on:keyup="hasToBeSaved()" required>
                                    <div class="error text-left" v-if="!$v.user.company.zipcode.required">
                                      {{$t('field-required')}}</div>
                                  </div>
                                </div>
                              </div>

                              <div class="fileld" v-if="user.company">
                                <div class="row">
                                  <label class="col-3">{{$t('city')}}</label>
                                  <div class="col-9">
                                    <input type="text" class="form-control" id="company_city"
                                      v-model='$v.user.company.city.$model' :placeholder="$t('placeholder-city')"
                                      v-on:click="$event.target.select()" v-on:keyup="hasToBeSaved()" required>
                                    <div class="error text-left" v-if="!$v.user.company.city.required">
                                      {{$t('field-required')}}</div>
                                  </div>
                                </div>
                              </div>

                              <div class="fileld" v-if="user.company">
                                <div class="row">
                                  <label class="col-3">{{$t('province')}}</label>
                                  <div class="col-9">
                                    <input type="text" class="form-control" id="company_province"
                                      v-model='$v.user.company.province.$model'
                                      :placeholder="$t('placeholder-province')" v-on:click="$event.target.select()"
                                      v-on:keyup="hasToBeSaved()" required>
                                    <div class="error text-left" v-if="!$v.user.company.province.required">
                                      {{$t('field-required')}}</div>
                                  </div>
                                </div>
                              </div>

                              <div class="fileld" v-if="user.company">
                                <div class="row">
                                  <label class="col-3">{{$t('country')}}</label>
                                  <div class="btn-group col-9">
                                    <select class="form-control" v-model='user.company.country'
                                      v-on:change="hasToBeSaved()">
                                      <option value="any">any</option>
                                      <option v-for="c in countries" :key="c.value" :value="c.value">{{c.text}}</option>
                                    </select>
                                  </div>
                                </div>
                              </div>

                              <div class="fileld" v-if="user.company">
                                <div class="row">
                                  <label class="col-3">{{$t('language')}}</label>
                                  <div class="btn-group col-9">
                                    <select class="form-control" v-model='currentLanguage'
                                      v-on:change="changeLanguage()">
                                      <option v-for="l in languages" :key="l.value" :value="l.value">{{l.text}}</option>
                                    </select>
                                  </div>
                                </div>
                              </div>

                              <div class="fileld" v-if="isAdmin">
                                <div class="row">
                                  <label class="col-3">{{$t('simulate-nonadmin-user')}}</label>
                                  <div class="col-9">
                                    <input type="checkbox" v-model='user.simulate_non_admin'
                                      v-on:change="toggleSimulateNonAdmin()" value="1">
                                  </div>
                                </div>
                              </div>

                              <div class="fileld">
                                <div class="row">
                                  <div class="col-lg-12 text-center"><button :disabled="!this.hasToBeSent || $v.$anyError	"
                                      type="button" class="btn btn-primary btn-lg btn-block add-selection add"
                                      v-on:click="save()">{{$t('save-changes')}}</button></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div id="sub-menu2" class="container tab-pane" :class="{ active: profile_section == 'change-password', fade: profile_section !== 'change-password'  }">
                        <div class="row">
                          <div class="col-lg-12 total-bg no_side_pad">
                            <h4>{{$t('change-password')}}</h4>
                            <div class="profile-settings" v-if="user">
                              <div class="fileld">
                                <div class="row">
                                  <label class="col-lg-3">{{$t('new-password')}}</label>
                                  <div class="col-lg-9"><input type="password" class="form-control" id="password"
                                      :placeholder="$t('placeholder-password')" v-model='user.password'
                                      v-on:keyup="hasToBeSaved()"></div>
                                </div>
                              </div>

                              <div class="fileld">
                                <div class="row">
                                  <label class="col-lg-3">{{$t('repeat-password')}}</label>
                                  <div class="col-lg-9"
                                    v-bind:class="{ 'has-error' : user.password != user.password_confirm &&  user.password_confirm}">
                                    <input type="password" class="form-control" id="repeat_password"
                                      name="repeat_password" :placeholder="$t('placeholder-password-repeat')"
                                      v-model='user.password_confirm' v-on:keyup="hasToBeSaved()"></div>
                                </div>
                              </div>



                              <div class="fileld">
                                <div class="row">
                                  <label class="col-lg-3"></label>
                                  <div class="col-lg-9"><button data-v-8c36869e=""
                                      :disabled="!this.hasToBeSent || user.password != user.password_confirm"
                                      type="button" class="btn btn-primary btn-lg btn-block add-selection add"
                                      v-on:click="save()">{{$t('change-password')}}</button></div>
                                </div>
                              </div>

                            </div>

                          </div>

                        </div>

                      </div>

                      <div id="sub-menu3" class="container tab-pane" v-bind:class="{ active: profile_section === 'notifications', fade: profile_section !== 'notifications' }">
                        <div class="row">
                          <div class="col-lg-12 total-bg no_side_pad">
                            <h4>{{$t('notifications')}}</h4>
                            <div class="profile-settings" v-if="user">

                              <div class="total-checkbox">
                                
                                <div class="check-one col-lg-12 no_side_pad d-flex align-items-center">
                                  <label class="col-sm-1 col-1">
                                    <input type="checkbox" name="optradio" v-model='user.newsletter' @change="hasToBeSaved()" />
                                  </label>
                                  <div class="text col-sm-11 col-11 no_side_pad ml-2">{{$t('receive-newsletter')}}</div>
                                </div>

                                
                                <div class="check-one col-lg-12 no_side_pad d-flex align-items-center">
                                  <label class="col-sm-1 col-1">
                                    <input type="checkbox" name="optradio" v-model='user.notifications_reports_weekly' @change="hasToBeSaved()" />
                                  </label>
                                  <div class="text col-sm-11 col-11 no_side_pad ml-2">{{$t('receive-reports-weekly')}}</div>
                                </div>

                                <div class="check-one col-lg-12 no_side_pad d-flex align-items-center">
                                  <label class="col-sm-1 col-1">
                                    <input type="checkbox" name="optradio" v-model='user.notifications_reports_monthly'
                                      v-on:change="hasToBeSaved()" />
                                  </label>
                                  <div class="text col-sm-11 col-11 no_side_pad ml-2">{{$t('receive-reports-monthly')}}</div>
                                </div>

                                <div class="check-one col-lg-12 no_side_pad d-flex align-items-center">
                                  <label class="col-sm-1 col-1">
                                    <input type="checkbox" name="optradio" v-model='user.notifications_low_balance'
                                      v-on:change="hasToBeSaved()" />
                                  </label>
                                  <div class="text col-sm-11 col-11 no_side_pad ml-2">{{$t('receive-low-balance', {amount: user && user.autotopup_threshold ? user.autotopup_threshold : 5, currency: currency })}}</div>
                                </div>


                                <div class="fileld pt-5 d-flex align-items-center" v-if="this.hasAutoCredits()">
                                  <label class="col-lg-3">{{$t('billing_email')}}</label>
                                  <div class="col-lg-6">
                                  <input type="email" class="form-control" id="billing_email" name="email" 
                                   v-model='user.billing_email' :placeholder="$t('placeholder-email-billing')" 
                                   v-on:keyup="hasToBeSaved()">
                                  </div>
                                </div>

                                <div class="fileld d-flex align-items-center">
                                  <label class="col-lg-3">{{$t('job_emails')}}</label>
                                  <div class="col-lg-7">
                                  <input type="email" class="form-control" id="job_emails" name="email" 
                                   v-model='user.job_emails' :placeholder="$t('placeholder-email-job')" 
                                   v-on:keyup="hasToBeSaved()">
                                  </div>
                                </div>

                                <button data-v-47aa12d3="" data-v-8c36869e="" :disabled="!this.hasToBeSent"
                                  type="button" class="btn btn-primary btn-lg btn-block add-selection save-notification"
                                  v-on:click="save()">{{$t('save')}}</button>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>


                      <div id="sub-menu4" class="container tab-pane" v-bind:class="{ active: profile_section === 'system', fade: profile_section !== 'system' }">
                        <div class="row">
                          <div class="col-lg-12 total-bg no_side_pad">
                            <h4>{{$t('system')}}</h4>


                            <div class="profile-settings" v-if="user">

                              <div class="total-checkbox">
                                <div class="check-one col-lg-12 no_side_pad d-flex align-items-center pb-4">
                                  <label class="col-sm-1 col-1">
                                    <input type="checkbox" name="optradio" v-model='user.system_cache_enabled' @change="hasToBeSaved()" />
                                  </label>
                                  <div class="text col-sm-11 col-11 no_side_pad ml-2">{{$t('system-cache-enabled')}}</div>
                                </div>

                                <div class="fileld">
                                  <div class="row">
                                    <div class="col-lg-12 text-center"><button :disabled="!this.hasToBeSent || $v.$anyError	"
                                        type="button" class="btn btn-primary btn-lg btn-block add-selection add"
                                        v-on:click="save()">{{$t('save-changes')}}</button></div>
                                  </div>
                                </div>
                              
                              </div>
                            </div>
                            
                            <div class="profile-settings">
                              <div class="system">
                                <div class="export d-flex justify-content-between align-items-center">
                                  <div class="content">{{$t('system-export-account')}}</div>
                                  <button v-on:click="exportAccount()" type="button"
                                  class="btn btn-primary export-account add">{{$t('system-export')}}</button>
                                </div>
                                <div class="delete d-flex justify-content-between align-items-center mt-3">
                                  <div class="content">{{$t('system-delete-account')}}</div>
                                  <button data-v-8c36869e="" v-on:click="deleteAccount()" type="button"
                                  class="btn btn-primary delete-account add">{{$t('system-delete')}}</button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                  <div class="col-lg-3 total-sub-tab">
                    <div class="wrap">
                      <ul class="nav nav-tabs">
                        <li class="nav-item" v-bind:class="{ active: profile_section === 'data' }"
                          v-on:click="setProfileSection('data')"><a>{{$t('data')}}</a></li>
                        <li class="nav-item" v-bind:class="{ active: profile_section === 'notifications' }"
                          v-on:click="setProfileSection('notifications')"><a>{{$t('notifications')}}</a></li>
                        <li class="nav-item" v-bind:class="{ active: profile_section === 'change-password' }"
                          v-on:click="setProfileSection('change-password')"><a>{{$t('change-password')}}</a></li>
                        <li class="nav-item" v-bind:class="{ active: profile_section === 'system' }"
                          v-on:click="setProfileSection('system')"><a>{{$t('system')}}</a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div id="menu1" class="container tab-pane" v-if="section === 'billing'"
                v-bind:class="{ active: section === 'billing' }">
                <div class="row no_side_margin" style="width: 100%">
                  <div v-if="this.user" class="col-lg-12 total-billing no_side_pad">
                    <h4>{{$t('your-balance')}}</h4>
                    <div class="balance">
                      <div class="amount">{{(user.credits * this.processing.costPerCredit) | numFormat('0.00')
                        }}{{currency}}</div>
                      <div class="row">
                        <div class="col-lg-12 text-center">
                          <div class="row justify-content-center">
                            <div class="col-lg-12 text-center">
                              <div class="col-lg-12 text-center">
                                <div>
                                  <button v-if="isAdmin" :key="1" type="button" class="btn btn-primary"
                                    @click="buyCredits(1, 'stripe')" style="margin: 2px;">{{ $t('add') }} 1{{ currency
                                    }}</button>

                                  <button v-for="pack in packs" :key="pack" type="button" class="btn btn-primary"
                                    :class="{'disabled': pack < amountPackage}" :disabled="pack < amountPackage"
                                    style="margin: 2px;" @click="buyCredits(pack, 'stripe')">{{ $t('add') }} {{ pack
                                    }}{{currency}}</button>
                                </div>
                              </div>
                              <small class="help text-muted">{{$t('payment-supported-methods')}}</small>
                            </div>
                            <div class="col-lg-3 text-center mt-2">
                              <h7 class="text-muted text-sm">{{$t('promocode-purchase')}}</h7>
                              <div class="input-group" style="height: 40px">
                                <input type="text" class="form-control" v-model="promo_code.requested" :placeholder="$t('promocode-placeholder')" style="height: 100%" @keyup="promo_code.code_sent = false" @focus="$event.target.select()" @keyup.enter="checkPromoCode">
                                <div class="input-group-append">
                                  <button class="btn btn-primary" @click="checkPromoCode" :disabled="promo_code.code_sent || !promo_code.requested" style="height: 100%">
                                  {{ $t('apply') }}
                                  </button>
                                </div>
                              </div>
                              <div v-if="promo_code.code_sent" class="mt-2">
                                <span v-if="promo_code.available" class="text-success" v-html="$t('promocode-accepted', {promocode: promo_code.accepted})"></span>
                                <span v-else class="text-danger">{{ $t('promocode-invalid') }}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row no_side_margin pt-4" style="width: 100%">
                    <div class="col-lg-12 total-billing no_side_pad">
                      <div class="row">
                        <div class="col-lg-12">
                          <h4>{{$t('autotopup-title')}}</h4>
                          <div class="row no_side_margin">
                            <div class="col-lg-12 pb-4">
                              <p>{{$t('autotopup-description')}}</p>
                              <div class="pl-4 pt-3 pb-4">
                                <input class="form-check-input" type="checkbox" v-model="user.autotopup_enabled" @change="saveAndReloadUser()">
                                <label class="text-sm">{{$t('autotopup-enable')}}</label>
                              </div>
                            </div>

                            <div class="col-lg-12 pl-4 pb-4 pr-4" v-if="user && user.autotopup_enabled">
                                <div class="row">
                                    <div class="col-lg-3 col-6">
                                      <label for="autotopup_threshold">{{$t('autotopup-amount-to-detect')}}</label>
                                    </div>
                                    <div class="col-lg-3 col-6">
                                      <select class="form-control" id="autotopup_threshold" v-model="user.autotopup_threshold" @change="hasToBeSaved()">
                                        <option v-for="amount in minimumAmounts" :key="amount" :value="amount">{{ amount }}{{currency}}</option>
                                      </select>
                                    </div>
                                    <div class="col-lg-3 col-6">
                                      <label for="autotopup_amount">{{$t('autotopup-amount-to-add')}}</label>
                                    </div>
                                    <div class="col-lg-3 col-6">
                                      <select class="form-control" id="autotopup_amount" v-model="user.autotopup_amount" @change="hasToBeSaved()">
                                        <option v-for="pack in packs" :key="pack" :value="pack">{{ pack }}{{currency}}</option>
                                      </select>
                                    </div>
                                </div>
                              <div class="row">
                                <div class="col-lg-12">
                                    <div class="text-center">
                                      <button v-if="user.autotopup_status === 'activated'"
                                      type="button" class="btn btn-primary btn-danger add-selection add"
                                      v-on:click="deactivateAutoTopup()">
                                      <span v-if="user.autotopup_status === 'deactivating'">
                                        <i class="fa fa-spinner fa-spin"></i> {{$t('autotopup-deactivating')}}
                                      </span>
                                      <span v-else>
                                        {{$t('autotopup-deactivate')}}
                                      </span>
                                      </button>
                                      <button 
                                      :disabled="this.sending"
                                      type="button" class="btn btn-primary add-selection add"
                                      :key="user.autotopup_status"
                                      v-on:click="saveAutoTopup()"
                                      style="margin-left: 10px;">
                                      <span v-if="user.autotopup_status === 'activated'">
                                        {{$t('autotopup-save-changes')}}
                                      </span>
                                      <span v-else-if="user.autotopup_status === 'pending'">
                                        <i class="fa fa-spinner fa-spin"></i> {{$t('autotopup-activating')}}
                                      </span>
                                      <span v-else>
                                        {{$t('autotopup-activate')}}
                                      </span>
                                      </button>
                                    </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>  

                <div class="row no_side_margin pt-4" style="width: 100%">
                  <div v-if="user && payments && payments.length" class="col-lg-12 payment-method total-payment">
                    <div class="row">
                      <div class="col-lg-10">
                        <h4>{{$t('payments')}}</h4>
                      </div>
                      <div class="col-lg-2">
                        <select class="form-control" v-model="payment_year" name="year"
                          v-on:change="filterPayments">
                          <option :value="year" v-for="year in this.payment_years" :key="year">{{year}}</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div v-if="user && payments && payments.length" class="list-scroller" style="overflow-x: auto;">
                    <table class="table table-striped list-table"
                      style="margin-top: 0px ; box-shadow: none; margin-bottom: 0px;">
                      <thead>
                        <tr>
                          <th class="t-head col-lg-3">{{$t('date')}}</th>
                          <th class="t-head col-lg-2">{{$t('type')}}</th>
                          <th class="t-head col-lg-2 text-right">{{$t('total')}}</th>
                          <!-- <th class="t-head col-lg-2 text-right">{{$t('vat')}}</th>
                          <th class="t-head col-lg-2 text-right">{{$t('total')}}</th> -->
                          <th class="t-head col-lg-1 text-right"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="payment in payments" :key="payment.createdAt">
                          <td class="table-col">{{payment.creation_date | formatDate}}</td>
                          <td class="table-col">{{$t("payment-type-" + payment.type)}}</td>
                          <!-- <td class="table-col">{{$t(payment.code && payment.code.length > 0 ? "yes" : "no")}}</td> -->
                          <!-- <td class="table-col text-right">{{payment.amount | numFormat('0.00') }}{{currency}}</td> -->
                          <!-- <td class="table-col text-right">-</td> -->
                          <!-- <td class="table-col text-right">{{payment.amount_vat - payment.amount | numFormat('0.00')}}{{currency}}</td> -->
                          <!-- <td class="table-col text-right">{{payment.amount_vat | numFormat('0.00')}}{{currency}}</td> -->
                          <td class="table-col text-right">{{payment.amount | numFormat('0.00') }}{{currency}}</td>
                          <td class="table-col text-right">
                            <a title="View invoice" :href="payment.invoice_url" target="_blank"
                              v-if="payment.invoice_url && payment.invoice_url.length"><i class="fa fa-eye"></i></a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <!-- <div class="row no_side_margin pt-4" style="width: 100%">
                    <div v-if="user && payments && payments.length" class="col-lg-12 payment-method total-payment">
                      <div class="row">
                        <div class="col-lg-10">
                          <h4>{{$t('invoices')}}</h4>
                        </div>
                        <div class="col-lg-2">
                          <select class="form-control" v-model="invoice_year" name="year"
                            v-on:change="filterInvoices">
                            <option :value="year" v-for="year in this.invoice_years" :key="year">{{year}}</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div> -->

                  <!-- <div v-if="invoices && invoices.length" class="list-scroller" style="overflow-x: auto;">

                    <table class="table table-striped list-table"
                      style="margin-top: 0px ; box-shadow: none; margin-bottom: 0px;">
                      <thead>
                        <tr>
                          <th class="t-head col-lg-2">{{$t('date')}}</th>
                          <th class="t-head">{{$t('number')}}</th>
                          <th class="t-head col-lg-1 text-right">{{$t('base')}}</th>
                          <th class="t-head col-lg-1 text-right">{{$t('vat')}}</th>
                          <th class="t-head col-lg-1 text-right">{{$t('total')}}</th>
                          <th class="t-head col-lg-1"></th>
                        </tr>
                      </thead>

                      <tr v-for="invoice in invoices" :key="invoice.number">
                        <td class="table-col" v-if="!user.autocredits">{{invoice.createdAt | formatDate}}</td>
                        <td class="table-col" v-if="user.autocredits">{{invoice.date}}</td>
                        <td class="table-col">UPROC{{invoice.year}}{{invoice.number | numFormat('0000') }}</td>
                        <td class="table-col text-right">{{invoice.amount | numFormat('0.00') }}{{currency}}</td>
                        <td class="table-col text-right">{{invoice.amountVat | numFormat('0.00')}}{{currency}}</td>
                        <td class="table-col text-right">{{invoice.amountTotal | numFormat('0.00')}}{{currency}}</td>
                        <td class="table-col text-right">
                          <a title="View invoice" v-on:click="viewInvoice(invoice.year,  invoice.number)"
                            v-bind:href="'#/settings/billing'"><i class="fa fa-eye"></i></a>
                        </td>
                      </tr>
                    </table>
                  </div> -->
                </div>
              </div>

              <!-- <div id="menu3" class="container tab-pane" v-if="section === 'invoice'"
                v-bind:class="{ active: section === 'invoice' }">
                <div class="row no_side_margin">
                  <div class="col-sm-6 col-title ">
                    <h4>{{$t('invoice')}}</h4>
                  </div>
                  <div class="col-sm-6 text-right">
                    <button class="btn btn-default btn-primary" v-on:click="close()">{{$t('back')}}</button>
                    &nbsp;
                    <button class="btn btn-default btn-primary" v-on:click="print()">{{$t('print')}}</button>
                  </div>
                  <div class="row no_side_margin pt-4" style="width: 100%">

                    <div class="col-lg-12 total-billing no_side_pad text-center">
                      <iframe width="100%" name="invoice" id="invoice" height="1400" frameborder="0"
                        :srcdoc="invoice_html"></iframe>
                    </div>
                  </div>
                </div>
              </div> -->

              <div id="menu3" class="container tab-pane" v-if="section === 'integration'"
                v-bind:class="{ active: section === 'integration' }">
                <div class=" row no_side_margin" v-if="subsection === 'oauth'">
                  <div class="col-lg-12 no_side_pad">
                    <div class="allowed-source col-lg-12">
                      <div class="total-source">
                        <div class="row no_side_margin">
                          <div class="col-lg-12 no_side_pad">
                            <span>{{$t('applications-adding', {provider: $route.params.provider})}}
                              <span
                                v-bind:class="{'text-success': application_adding_status === 'success', 'text-error': application_adding_status === 'error', 'text-warning': application_adding_status === 'pending'}">
                                <span v-if="!provider_error.length">{{$t('applications-adding-' +
                                  application_adding_status)}}</span>
                                <span class="error" v-if="provider_error.length">{{provider_error}}</span>
                              </span>
                            </span>
                          </div>

                        </div>
                        <div class="col-lg-12 no_side_pad"
                          v-if="provider_error.length && $route.params.provider === 'hubspot'">
                          <a href="https://app.hubspot.com/oauth/authorize?client_id=75c836a3-ec0c-4a27-b431-11b36b2c1cfa&redirect_uri=https://app.uproc.io/oauth/hubspot/enrichmycontacts&scope=contacts"
                            target="_blank">{{$t('applications-restart')}}</a>
                        </div>
                        <div class="col-lg-12 no_side_pad"
                          v-if="!provider_error.length && application_adding_status === 'success'">
                          <span>{{$t('applications-reload')}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class=" row no_side_margin" v-if="subsection !== 'oauth'">
                  <div class="col-lg-12 total-credential no_side_pad">
                    <h4>{{$t('api-credentials')}}</h4>
                    <div class="api-credential" v-if="this.user">
                      <div class="field">
                        <div class="row">
                          <label class="col-lg-3">{{$t('email')}}</label>
                          <div class="col-lg-9"><input type="email" class="form-control" id="email"
                              v-bind:value="this.user.email" readonly v-on:click="$event.target.select()">
                          </div>
                        </div>
                      </div>

                      <div class="field">
                        <div class="row">
                          <label class="col-lg-3">{{$t('api-real-key')}}</label>
                          <div class="col-lg-9"><input type="email" class="form-control" id="email"
                              v-bind:value="this.user.apiKey" readonly v-on:click="$event.target.select()">
                          </div>
                        </div>
                      </div>

                      <div class="field">
                        <div class="row">
                          <label class="col-lg-3">{{$t('api-test-key')}}</label>
                          <div class="col-lg-9"><input type="email" class="form-control" id="email"
                              v-bind:value="this.user.apiKey+'test'" readonly v-on:click="$event.target.select()">
                            <small>{{$t('apikey-test-help')}}</small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>





                  <div class="allowed-source col-lg-12">
                    <div class="total-source">
                      <div class="row no_side_margin">
                        <div class="col-lg-9 no_side_pad">
                          <h4>{{$t('allowed-sources')}}</h4>
                        </div>
                        <div class="col-lg-3">
                          <button type="button" class="save-change-btn" v-on:click="save()"
                            v-bind:class="{'disabled': !this.hasToBeSent}" v-bind:disabled="!this.hasToBeSent"> Save
                            changes</button>
                        </div>
                      </div>
                    </div>

                  </div>
                  <div class="col-lg-12 add-source">
                    <p>{{$t('allowed-sources-description')}}</p>
                    <div class="row no-side-margin">
                      <div class="col-lg-8">
                        <h5>{{$t('allowed-sources-new')}}</h5>
                        <div class="row no-side-margin">
                          <div class="col-lg-7 col-md-7 col-sm-7 no_side_pad">
                            <input type="text" class="form-control add-source-field" v-model="newSource">
                          </div>
                          <div class="col-lg-3 col-md-3 col-sm-3 no_side_pad">
                            <button type="button" class="add-source-btn"
                              v-bind:disabled="this.newSource && !this.newSource.length" v-on:click="addSource()">
                              {{$t('allowed-sources-add')}}</button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row no-side-margin"
                      v-if="this.user && this.user.allowed_sources && this.user.allowed_sources.length">
                      <div class="col-lg-8 col-md-8 source-manager">
                        <h5>Sources manager</h5>
                        <div class="row total-source-manager no-side-margin"
                          v-for="(source, index) in this.user.allowed_sources" :key="index">
                          <div class="col-lg-7 col-md-7 col-sm-7 no_side_pad">
                            <input type="text" class="form-control add-source-field" readonly v-bind:value="source">
                          </div>
                          <div class="col-lg-3 col-md-3 col-sm-3 no_side_pad">
                            <button type="button" class="delete-btn" v-on:click="removeSource(index)"> Delete</button>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>

                </div>
              </div>

              <div id="menu4" class="container tab-pane" v-if="section === 'agreements'"
                v-bind:class="{ active: section === 'agreements' }">

                <div class="row">
                  <div class="col-sm-12 col-title ">
                    <h4>{{$t('agreement')}}</h4>
                  </div>
                  <div class="list-scroller" style="overflow-x: auto;">
                    <table class="table table-striped list-table"
                      style="margin-top: 0px ; box-shadow: none; margin-bottom: 0px;" v-if="this.user">
                      <thead>
                        <tr>
                          <th class="t-head">{{$t('date')}}</th>
                          <th class="t-head">{{$t('name')}}</th>
                          <th class="t-head"></th>
                        </tr>
                      </thead>
                      <tbody v-if="this.user">

                        <tr v-for="agreement in user.contracts" :key="agreement.date">
                          <th class="t-head">{{ agreement.date | formatDate }}</th>
                          <th class="t-head">{{ agreement.name }}</th>
                          <th class="t-head">
                            <a v-bind:href="'https://api.uproc.io/api/v1/' + user.apiKey + '/docs/download/' + agreement.name"
                              analytics-on="click" uib-tooltip-placement="left" uib-tooltip="download"
                              analytics-event="Download doc" analytics-category="Job actions" target="_blank"
                              alt="download" title="download">{{$t('download')}}
                              <i class="fa fa-download" aria-hidden="true"></i>
                            </a>
                          </th>



                        </tr>

                      </tbody>
                    </table>
                  </div>
                  <div class="col-lg-12 no-avai" v-if="this.user && !this.user.contracts.length">{{$t('no-agreements')}}
                  </div>


                  <div class="col-lg-12 no_side_pad" v-if="this.user && !this.user.contracts.length">
                    <div class="chose-file">
                      <p v-html="$t('no-agreements-description')"></p>
                      <div class="chose">
                        <input type="file" class="upload" v-on:change="uploadFile()" id="file" ref="file"
                          :disabled="this.isProcessing">
                      </div>
                    </div>
                  </div>

                </div>

              </div>

              <div id="menu5" class="container tab-pane total" v-if="section === 'affiliate' && user.referrals"
                v-bind:class="{ active: section === 'affiliate' }">
                <div class="row no_side_margin" v-if="isAdmin && user">
                  <div class="col-lg-12 total-credential no_side_pad">
                    <h4>{{$t('invite-stats')}}</h4>
                    <div class="row smry-status2" v-if="this.user">
                      <div class="col-lg-4 text-center item">
                        <div class="strong item-num">{{ user.referrals.users }}</div>
                        <small class="kpi-label">{{ $t('invite-users') }}</small>
                      </div>
                      <div class="col-lg-4 text-center item">
                        <div class="strong item-num">{{ user.referrals.sales }}</div>
                        <small class="kpi-label">{{ $t('invite-sales') }}</small>
                      </div>
                      <div class="col-lg-4 text-center item">
                        <div class="strong item-num">{{ user.referrals.balance | numFormat('0.00') }}{{ currency }}</div>
                        <small class="kpi-label">{{ $t('invite-balance') }}</small>
                      </div>
                    </div>
                  </div>
                </div>

              
                <div class="row no_side_margin pt-4">
                  <div class="col-lg-12 total-credential no_side_pad">
                  <h4>{{$t('invite-share')}}</h4>
                  <div class="api-credential" v-if="this.user">
                    <div class="row">
                    <div class="field col-lg-12 text-center">
                      <span v-html="$t('invite-win', {currency: currency})"></span>:
                      <a v-if="user.promo_code && user.promo_code.length" v-clipboard:copy="referral_link" v-clipboard:success="onCopy" href="javascript: void()"><strong>{{user.promo_code}}</strong></a>
                      <button v-if="!user.promo_code || (user.promo_code && !user.promo_code.length)"
                      class="btn btn-primary" v-on:click="save()">
                      {{$t('generate')}}
                      </button>
                    </div>

                    <div class="col-lg-6 mt-3" v-if="user.promo_code && user.promo_code.length">
                      <h4>{{$t('invite-friends')}}</h4>
                      <textarea v-on:keyup="checkEmails()" class="form-control" :placeholder="$t('invite-friends-email')" required v-model="emails"></textarea>
                      <button :disabled="!hasValidEmails" class="btn btn-primary btn-md mt-2" v-on:click="sendInvitations()">
                      {{$t('send')}}
                      </button>
                      <ul class="list-group list-group-flush mt-3" v-if="invitations && invitations.length">
                      <li class="list-group-item" v-for="i in invitations" :key="i.email">{{i.email}}:
                        <span v-if="i.result" class="text-success">{{$t('sent')}}</span>
                        <span v-if="!i.result" class="text-danger">{{$t('not-sent-because')}} {{i.reason}}</span>
                      </li>
                      </ul>
                    </div>

                    <div class="col-lg-6 mt-3" v-if="user.promo_code && user.promo_code.length">
                      <h4>{{$t('invite-link')}}</h4>
                        
                      <div class="text-center mt-3">
                        <!-- <a target="_blank" :title="$t('invite-share-title', {amount: 1})" :href="'mailto:?subject=' + encodeURIComponent($t('invite-share-subject')) + '&body=' + encodeURIComponent($t('invite-share-body')) + ' ' + encodeURIComponent(referral_link)" class="mx-2">
                          <i class="fa fa-2x fa-envelope"></i>
                        </a> -->
                        <a target="_blank" :title="$t('invite-share-title', {amount: 1})" :href="'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(referral_link)" class="mx-2">
                          <i class="fa fa-2x fa-facebook"></i>
                        </a>
                        <a target="_blank" :title="$t('invite-share-title', {amount: 1})" :href="'https://twitter.com/intent/tweet?url=' + encodeURIComponent(referral_link)" class="mx-2">
                          <i class="fa fa-2x fa-twitter"></i>
                        </a>
                        <a target="_blank" :title="$t('invite-share-title', {amount: 1})" :href="'https://www.linkedin.com/shareArticle?mini=true&url=' + encodeURIComponent(referral_link)" class="mx-2">
                          <i class="fa fa-2x fa-linkedin"></i>
                        </a>
                        <a target="_blank" :title="$t('invite-share-title', {amount: 1})" :href="'https://wa.me/?text=' + encodeURIComponent(referral_link)" class="mx-2">
                          <i class="fa fa-2x fa-whatsapp"></i>
                        </a>
                        <a target="_blank" :title="$t('invite-share-title', {amount: 1})" :href="'https://t.me/share/url?url=' + encodeURIComponent(referral_link)" class="mx-2">
                          <i class="fa fa-2x fa-telegram"></i>
                        </a>
                      </div>
                    </div>
                    </div>
                  </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

        <v-dialog />


        <!-- settings content start end -->


        <Footer />

      </div>


    </div>

  </div>
</template>

<script>
import axios from 'axios';
import { required } from 'vuelidate/lib/validators'

import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import Sidebar from '@/components/Sidebar.vue'

export default {
  name: 'settings',
  data() {
    return {

      isActive: false,
      isActiveIcon: '<',

      user: {
        autotopup_enabled: false,
        autotopup_threshold: 1,
        autotopup_amount: 25,
        autotopup_status: 'deactivated',
      },
      
      FromServerUser: null,
      countries: null,
      selectedCountry: null,
      hasToBeSent: false,
      sending: false,
      payments: null,
      processing: [],
      hasCompletedFiscalData: false,
      refs: null,
      card: [],
      errorMsg: '',
      CardValid: true,
      newSource: '',
      files: '',
      isError: '',
      isProcessing: false,
      active: true,
      isAdmin: false,
      amountPackage: 0,

      deleteReason: '',

      link_copied: false,
      referral_link: "",
      sent: false,
      emails: "",
      hasValidEmails: false,
      invitations: [],

      providers: ['Email', 'LinkedIn'],
      //providers: ['LinkedIn', 'Email', 'Email_Intern'],
      provider: {
        provider: 'LinkedIn',
        credentials: {}
      },

      section: 'profile',
      subsection: '',
      profile_section: 'data',

      invoices: [],
      invoice_years: [],
      payment_years: [],
      invoice_year: '',
      payment_year: '',

      newApplicationShown: false,

      invoice_html: "",
      selected_invoice_year: "",
      selected_invoice_number: "",


      application_adding_status: '',
      provider_error: '',

      list_entry_cost: 25,

      promo_code: {
        code_sent: false,
        available: false,
        requested: "",
        accepted: "",
        type: "",
        amount: "",
        originalAmount: ""
      },
      packs: [10, 25, 50, 100, 250],
      minimumAmounts: [1,2,5],
      currency: '€',

      showAllPackages: false,

      currentLanguage: this.$i18n.locale,
      languages: [
        {
          value: 'en',
          text: 'English'
        },
        {
          value: 'es',
          text: 'Español'
        },
        
      ],

      checkoutSession: null,
      setupIntent: null,

    }

  },
  validations: {
    user: {
      name: {
        required
      },
      company: {
        name: {
          required
        },
        cif: {
          required
        },
        phone: {
          required
        },
        address: {
          required
        },
        zipcode: {
          required
        },
        city: {
          required
        },
        province: {
          required
        }
      },
      country_code: {
        required
      }
    },
  },

  mounted() {
    this.addNewLanguages();
    this.enableInterceptor();
    this.getUserDetails();
    this.getProcessingValues();
    this.getPayments();
    this.getCountries()
    this.refs = this.$refs;
    this.checkIfSavedPaymentSession();
    this.detectSections();
    this.detectPromoCodeInUrl();
  },
  components: {

    Header,
    Sidebar,
    Footer

  },
  watch: {
    countries: function() {
      if (this.user != null && this.user.country_code) {
        this.selectedCountry = this.checkCountry(this.user.country_code);
      } else {
        this.selectedCountry = 'any';
      }
    }
  },
  methods: {
    getAuthConfig: function() {
      return {
        headers: {
          "Authorization": "Basic " + btoa(localStorage.configEmail + ":" + localStorage.token),
          "Content-Type": "application/json"
        }
      };
    },

    addNewLanguages: function() {
      // this.languages.push({
      //     value: 'fr',
      //     text: 'Français'
      //   });
        // {
        //   value: 'de',
        //   text: 'Deutsch'
        // };
    },

    detectSections() {
      const path = this.$route.fullPath;
      if (path.indexOf("profile") !== -1) {
        this.setSection('profile')
        this.setProfileSection('data')
        if (path.indexOf('notifications') !== -1) {
          this.setProfileSection('notifications')
        } else if (path.indexOf('system') !== -1) {
          this.setProfileSection('system')
        } else if (path.indexOf('change-password') !== -1) {
          this.setProfileSection('change-password')
        }
      } else if (path.indexOf("billing") !== -1) {
        this.setSection('billing')
      } else if (path.indexOf("integration") !== -1) {
        this.setSection('integration')
      } else if (path.indexOf("agreements") !== -1) {
        this.setSection('agreements')
      } else if (path.indexOf("affiliate") !== -1) {
        this.setSection('affiliate')
      }

      if (path.indexOf("oauth") !== -1) {
        //we receive integrations for external services like Hubspot, Salesforce, ...
        //All URI have next format: /#/settings/integration/METHOD/PROVIDER?PARAM1=VALUE1
        //Sample URI: #/settings/integration/oauth/hubspot/customers?code=CODE_TO_USE
        this.subsection = 'oauth';
      }

      if (this.subsection === 'oauth') {
        //send data to server
        this.addOAuthIntegration();
      }
    },

    detectPromoCodeInUrl() {
      const query = this.$route.query;
      if (query.promo_code) {
        this.promo_code.requested = query.promo_code;
        this.checkPromoCode();
      }
    },

    initPromoCode() {
      this.promo_code = {
        code_sent: false,
        available: false,
        requested: "",
        accepted: "",
        type: "",
        amount: "",
        originalAmount: ""
      }
    },

    getCurrentLanguage: function() {
      var lang = localStorage.getItem('lang');
      if (lang) {
        this.currentLanguage = lang;
      } else {
        this.currentLanguage = this.$i18n.locale;
      }
    },

    changeLanguage: function() {
      this.$i18n.locale = this.currentLanguage.toLowerCase();
      localStorage.setItem('lang', this.currentLanguage.toLowerCase());
    },

    buyCredits: function(amount, method) {
      this.loading = true;
      var url = '/api/v2/buy';
      if (method === 'stripe') {
        url = url = '/api/v2/purchase_session';
      }

      var data = {
        method: method,
        amount: (parseInt(amount * 100, 10) / 100),
        promo: this.promo_code.accepted,
        source: 'settings/billing'
      }
      this.$http.post(url, data, this.getAuthConfig()).then((results) => {
        this.loading = false;
        this.$emit('isLoading', false);
        var res = results.data;
        this.oldMethod = this.method;
        this.method = "";
        this.checkout = false;
        if (res.error && res.error.length) {
          this.$toast.error(this.$t('payment-error') + ': ' + res.error);
          return;
        } else {
          this.initPromoCode();
          this.savePaymentSessionToStorage(res.session);
          if (method !== 'stripe') {
            this.purchaseDone = true;
            this.purchaseResult = res;
          } else {
            this.enableMethod(method, res);
          }
        }
      });
    },

    enableMethod: function(method, data) {
      if (method === 'stripe') {
        //console.log(data)
        window.location.href = data.session_url
      }
    },

    redirectToBilling: function() {
      if (this.$route.fullPath !== '/settings/billing') {
        this.$router.push('/settings/billing');
      }
    },

    checkPromoCode() {
      this.promo_code.code_sent = false
      this.promo_code.requested = this.promo_code.requested.toUpperCase();
      var url = '/api/v2/promo_code/' + this.promo_code.requested;
      this.$http.get(url, this.getAuthConfig()).then((results) => {
        this.$nextTick(() => {
          this.promo_code.code_sent = true
          this.promo_code.available = results.data.available;
          if (this.promo_code.available) {
            this.promo_code.accepted = this.promo_code.requested;
            this.promo_code.requested = "";
            this.promo_code.type = results.data.type;
            this.promo_code.originalAmount = results.data.amount;
            //this.recalculatePromo();
          }
          this.$forceUpdate();
        });
      });
    },
    
    checkIfSavedPaymentSession() {
      const query = this.$route.query;
      if (localStorage.checkoutSession && localStorage.checkoutSession.length) {
        this.restorePaymentSessionFromStorage();
        if (this.checkoutSession && this.checkoutSession.length) {
          console.log(query);
          //returned url contains ?payment=success if payment was successful
          if (query.payment) {
            if (query.payment === 'success') {
              this.confirmPurchase();
            } else {
              this.cancelPurchase();
            }
          } else if (query.autopayments) {
            if (query.autopayments === 'success') {
              this.confirmAutoPayments();
            } else {
              this.cancelAutoPayments();
            }
          }
        } else {
          this.redirectToBilling();
        }
      }
    },

    confirmPurchase() {
      var url = '/api/v2/confirm_purchase';
      var data = {
        session: this.checkoutSession
      }
      this.$http.post(url, data, this.getAuthConfig()).then((results) => {
        this.purchaseDone = true;
        this.purchaseResult = results.data.success
        if (this.purchaseResult) {
          this.$toast.success(this.$t('payment-success'));
          this.getUserDetails();
          this.loadPayments(true);
        } else {
          this.$toast.error(this.$t('payment-error'));
        }
        this.clearPaymentSessionFromStorage();
        this.redirectToBilling();
      });
    },

    cancelPurchase: function() {
      this.loading = true;
      var url = '/api/v2/cancel_purchase';
      const data = {
        session: this.checkoutSession
      }
      //console.log(data);
      this.$http.post(url, data, this.getAuthConfig()).then(() => {
        this.loading = false;
        this.$emit('isLoading', false);
        this.clearPaymentSessionFromStorage();
        this.$toast.error(this.$t('payment-cancel'));
        this.redirectToBilling();
      });
    },

    startAutoTopupCheckout: function() {
      this.sending = true;
      this.$emit('isLoading', true);
      var url = '/api/v2/autopayments_session';
      this.$http.get(url, this.getAuthConfig()).then((results) => {
        var res = results.data;
        if (res.error && res.error.length) {
          this.sending = false;
          this.$emit('isLoading', false);
          this.$toast.error(this.$t('payment-error') + ': ' + res.error);
          return;
        } else {
          this.savePaymentSessionToStorage(res.session);
          this.savePaymentSetupToStorage(res.setup_intent);
          this.enableMethod('stripe', res);
        }
      });
    },

    confirmAutoPayments() {
      this.$emit('isLoading', true);
      this.loading = true;
      var url = '/api/v2/confirm_autopayments';
      var data = {
        session: this.checkoutSession,
        setup_intent: this.setupIntent
      }
      this.$http.post(url, data, this.getAuthConfig()).then((results) => {
        this.loading = false;
        this.$emit('isLoading', false);
        if (results.data.success) {
          this.$toast.success(this.$t('autopayments-success'));
          this.getUserDetails();
        } else {
          this.$toast.error(this.$t('autopayments-error'));
        }
        this.clearPaymentSessionFromStorage();
        this.redirectToBilling();
      });
    },

    cancelAutoPayments: function() {
      //   this.clearPaymentSessionFromStorage();
      this.$toast.error(this.$t('autopayments-cancel'));
      //   this.redirectToBilling();
    },

    savePaymentSessionToStorage : function(checkoutSession) {
      localStorage.promoCode = JSON.stringify(this.promo_code);
      localStorage.checkoutSession = JSON.stringify(checkoutSession);
    }, 

    savePaymentSetupToStorage : function(setupIntent) {
      localStorage.setupIntent = JSON.stringify(setupIntent);
    }, 

    restorePaymentSessionFromStorage : function() {
      this.promo_code = JSON.parse(localStorage.promoCode);
      this.checkoutSession = JSON.parse(localStorage.checkoutSession);
      this.setupIntent = localStorage.setupIntent ? JSON.parse(localStorage.setupIntent) : null;
    },

    clearPaymentSessionFromStorage : function() {
      localStorage.removeItem('promoCode');
      localStorage.removeItem('checkoutSession');
      localStorage.removeItem('setupIntent');
    },
    
    addOAuthIntegration: function() {
      var url = '/api/v2/integration/oauth';
      var data = {
        provider: this.$route.params.provider,
        app: this.$route.params.app,
        credentials: this.$route.query
      }
      //console.log(data);
      this.application_adding_status = 'pending';
      this.$http.post(url, data, this.getAuthConfig()).then((results) => {
        if (!results.data.result) {
          this.application_adding_status = 'error';
          this.provider_error = results.data.error;
        } else {
          this.application_adding_status = 'success';
          this.provider_error = "";
          setTimeout(()=>{
            this.$router.push('/settings/integration');
            location.reload();
          }, 2000);
        }
      });
    },

    setProfileSection(section) {
      this.profile_section = section;
      
      console.log(section);

      if (section === 'data') {
        if (!this.isCurrentRoute('/settings/profile')) {
          this.$router.push('/settings/profile');
        }
      } else {
        if (!this.isCurrentRoute('/settings/profile/' + section)) {
          this.$router.push('/settings/profile/' + section);
        }
      }
    },

    isCurrentRoute: function(route) {
      //console.log(this.$route.path, route);
      return this.$route.path === route;
    },

    enableInterceptor() {
      this.axiosInterceptor = axios.interceptors.request.use((config) => {
        this.$root.$emit('isLoading', true);
        return config
      }, (error) => {
        this.$root.$emit('isLoading', false);
        return Promise.reject(error)
      })

      axios.interceptors.response.use((response) => {
        this.$root.$emit('isLoading', false);
        return response
      }, function(error) {
        this.$root.$emit('isLoading', false);
        return Promise.reject(error)
      })
    },

    menuCollapse: function() {
      this.isActive = !this.isActive;
      //console.log(this.isActive);
      this.$root.$emit('isActived', this.isActive);

      if (this.isActive)
        this.isActiveIcon = '>'
      else
        this.isActiveIcon = '<'
    },

    changeSubActive: function() {
      this.active = false;
    },

    getUserDetails: function() {
      var url = '/api/v2/profile';
      this.$http.get(url, this.getAuthConfig()).then((results) => {
        this.user = results.data;
        this.isAdmin = this.user.role === 'admin';
        this.updateEntryCost();
        this.user.memberships.reverse();
        this.invoices = this.orderedInvoices(this.user.invoices_details);
        this.calculateInvoiceYears();

        this.user.authorization = btoa(localStorage.configEmail + ":" + this.user.apiKey);

        this.FromServerUser = JSON.parse(JSON.stringify(results.data));

        this.hasCompletedFiscalData = this.hasCompletedFiscalDataF();
        //this.$store.commit('setNeedsComplete', !this.hasCompletedFiscalData)

        var baseUrl = location.protocol + "//" + location.hostname;
        if (location.port === '3000') {
          baseUrl += ":" + location.port;
        }

        this.isAdmin = this.user.role === 'admin';
        this.referral_link = baseUrl + '/#/invitation/' + this.user.promo_code;

        this.detectSimulateNonAdmin();
      });
    },

    detectSimulateNonAdmin: function() {
      this.user.simulate_non_admin = localStorage.getItem('simulateNonAdmin', 'false') === 'true';
    },

    toggleSimulateNonAdmin: function() {
      this.user.simulate_non_admin = !this.user.simulate_non_admin;
      localStorage.setItem('simulateNonAdmin', this.user.simulate_non_admin + "");
    },

    hasCompletedFiscalDataF: function() {
      return this.user && this.user.company &&
        this.user.company.address && this.user.company.address.length > 0 &&
        this.user.company.cif && this.user.company.cif.length > 0 &&
        this.user.company.zipcode && this.user.company.zipcode.length > 0 &&
        this.user.company.city && this.user.company.city.length > 0 &&
        this.user.country_code.length > 0 &&
        this.user.company.name && this.user.company.name.length > 0 &&
        this.user.company.phone && this.user.company.phone.length > 0 &&
        this.user.company.province && this.user.company.province.length > 0;

    },

    getPayments: function() {
      if (this.payments === null) {
        this.loadPayments();
      }
    },

    loadPayments: function(forced, last_4) {
      forced = forced || false;
      if (this.payments === null || forced) {
        var url = '/api/v2/payments';
        url = last_4 !== undefined ? url + '/' + last_4 : url;
        this.$http.get(url, this.getAuthConfig()).then((results) => {
          //console.log(results.data)
          this.paymentsOriginal = results.data;
          this.payments = this.orderedPayments(this.paymentsOriginal);
          this.calculatePaymentYears()
        });
      }
    },

    getCountries: function() {

      var config = {
        headers: {
          "Authorization": "Basic " + btoa(localStorage.configEmail + ":" + localStorage.token),
          "Content-Type": "application/json"
        }
      };


      var url = '/api/v2/countries';

      this.$http.get(url, config).then((results) => {

        this.countries = results.data.countries.map(function(c) {
          return {
            text: c.name,
            value: c.code
          };
        });
      });

    },

    getCredits: function() {
      return this.user ? this.user.credits : 0;
    },

    hasCards: function() {
      return this.user && this.user.cards.length;
    },

    hasAutoCredits: function() {
      return this.user && this.user.autocredits !== null ? this.user.autocredits : false;
    },

    getEmail: function() {
      return this.user ? this.user.email : localStorage.configEmail ? localStorage.configEmail : "test@uproc.io";
    },

    areCreditsNeeded: function() {
      return this.processing.processCredits > this.getCredits();
    },

    //FROM HERE BULKS AREA FUNCTIONS


    selectCountry: function(pickedCountry) {
      if (pickedCountry == 'any') {
        this.selectedCountry = 'any';

        this.user.country_code = '';
      } else {

        this.selectedCountry = this.checkCountry(pickedCountry);

        this.user.country_code = pickedCountry;

      }



      if (JSON.stringify(this.user) === JSON.stringify(this.FromServerUser)) {

        this.hasToBeSent = false;

      } else {

        this.hasToBeSent = true;

      }

    },

    checkCountry: function(value) {
      for (var i = 0; i < this.countries.length; i++) {
        if (this.countries[i].value === value) {
          return this.countries[i].text;
        }
      }

    },

    addApplication: function() {
      this.provider.provider = this.provider.provider.toLowerCase();
      this.user.memberships.push(this.provider)
      this.save()
    },

    deleteApplication: function(index) {
      var that = this;
      this.$modal.show('dialog', {
        title: that.$t('applications-delete'),
        text: that.$t('applications-delete-question'),
        buttons: [{
            title: that.$t('accept'),
            handler: () => {
              that.user.memberships.splice(index, 1);
              that.save();
              that.$modal.hide('dialog');
            }
          },
          {
            title: that.$t('cancel')
          }
        ]
      })
    },

    hasToBeSaved: function() {
      //console.log(this.user)
      //console.log(this.FromServerUser)
      if (JSON.stringify(this.user) === JSON.stringify(this.FromServerUser)) {
        this.hasToBeSent = false;
      } else {
        this.hasToBeSent = true;
      }
    },

    saveAutoTopup: function() {
      let that = this;
      that.save(function() {
        that.getUserDetails();
        if (that.user.autotopup_status === 'deactivated' || that.user.autotopup_status === 'pending') {
          that.user.autotopup_status = 'pending';
          that.$forceUpdate();
          that.startAutoTopupCheckout();
        }
      });
    },

    deactivateAutoTopup: function() {
      const url = '/api/v2/cancel_autopayments';
      this.$http.get(url, this.getAuthConfig()).then(() => {
        this.$toast.success(this.$t('autopayments-cancel'));
        this.getUserDetails();
      });
    },

    save: function(callback) {
      this.$v.user.$touch();
      // if its still pending or an error is returned do not submit
      //if (this.$v.form.$error) return;
      this.sending = true;

      var userData = {
        name: this.user.name,
        plan: this.user.plan,
        email: this.user.email,
        billing_email: this.user.billing_email,
        job_emails: this.user.job_emails,
        oscar_emails: this.user.oscar_emails,
        emails: this.user.emails,
        password: this.user.password,
        newsletter: this.user.newsletter,
        webpush: this.user.webpush,
        cards: this.user.cards,
        notifications: this.user.notifications,

        system_cache_enabled: this.user.system_cache_enabled,

        notifications_reports_weekly: this.user.notifications_reports_weekly,
        notifications_reports_monthly: this.user.notifications_reports_monthly,
        notifications_low_balance: this.user.notifications_low_balance,

        lists_enrichment_personal_email: this.user.lists_enrichment_personal_email,
        lists_enrichment_personal_phone: this.user.lists_enrichment_personal_phone,
        lists_enrichment_web_visits: this.user.lists_enrichment_web_visits,
        lists_enrichment_business_type: this.user.lists_enrichment_business_type,

        lists_only_person_with_verified_email: this.user.lists_only_person_with_verified_email,
        lists_only_person_with_email: this.user.lists_only_person_with_email,
        lists_only_person_with_personal_email: this.user.lists_only_person_with_personal_email,
        lists_only_person_with_personal_phone: this.user.lists_only_person_with_personal_phone,
        lists_only_person_with_phone: this.user.lists_only_person_with_phone,
        lists_only_person_with_business_type: this.user.lists_only_person_with_business_type,
        lists_only_person_with_business_type_value: this.user.lists_only_person_with_business_type_value,
        lists_verify_email: this.user.lists_verify_email,
        lists_check_blacklist: this.user.lists_check_blacklist,
        lists_match_all_conditions: this.user.lists_match_all_conditions,
        lists_webhook: this.user.lists_webhook,

        company: this.user.company,
        invoices: this.user.invoices,
        
        attach: this.user.attach,
        favorites: this.user.favorites,
        allowed_sources: this.user.allowed_sources,
        pages: this.user.pages,
        country_code: this.user.country_code,
        memberships: this.user.memberships,
        webhooks: this.user.webhooks,

        autotopup_enabled: this.user.autotopup_enabled,
        autotopup_amount: this.user.autotopup_amount,
        autotopup_threshold: this.user.autotopup_threshold,
        autotopup_status: this.user.autotopup_status || 'deactivated',
      };

      //console.log(userData)

      var url = '/api/v2/profile';
      this.$http.post(url, userData, this.getAuthConfig()).then((results) => {
        this.user = results.data;
        this.newApplicationShown = false
        this.FromServerUser = JSON.parse(JSON.stringify(results.data));
        this.sending = false
        this.hasToBeSent = false;
        this.$toast.success(this.$t('saved-changes'));

        if (callback) {
          callback();
        }
      });
    },

    saveAndReloadUser: function() {
      var that = this;
      that.save(function() {
        that.getUserDetails();
      });
    },

    checkEmails: function() {
      this.invitations = [];
      var validateEmail = function(email) {
        return /^\S+@\S+$/.test(String(email).toLowerCase());
      }
      this.sent = false
      var splittedEmails = this.emails.split(",");
      var emailsLength = splittedEmails.length;

      var validEmails = splittedEmails.filter(function(e) {
        //console.log(e);
        return validateEmail(e);
      }).length;

      this.hasValidEmails = validEmails === emailsLength;
    },

    exportAccount: function() {

      var that = this;

      this.$modal.show('dialog', {
        title: that.$t('system-export'),
        text: that.$t('system-export-ask'),
        buttons: [{
            title: that.$t('accept'),
            handler: () => {

              var data = {
                user: that.user,
                payments: that.payments
              };

              var element = document.createElement('a');

              element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(JSON.stringify(data, undefined, 4)));

              element.setAttribute('download', "myprofile.json");

              element.style.display = 'none';

              document.body.appendChild(element);

              element.click();

              document.body.removeChild(element);

              that.$modal.hide('dialog');

            }
          },
          {
            title: that.$t('cancel')
          }
        ]
      })


    },

    deleteAccount: function() {
      var that = this;

      this.$modal.show('dialog', {
        title: that.$t('system-delete'),
        text: that.$t('system-delete-ask'),
        buttons: [{
            title: that.$t('accept'),
            handler: () => {
              that.deleteUser();
              that.$modal.hide('dialog');
            }
          },
          {
            title: that.$t('cancel')
          }
        ]
      });
    },

    deleteUser: function() {
      var url = '/api/v2/profile/delete';
      this.$http.get(url, this.getAuthConfig()).then((results) => {
        if (results.data.result) {
          localStorage.token = '';
          this.$router.push("/signup");
          location.reload();
        }
      });
    },

    getProcessingValues: function() {
      var url = '/api/v2/processing';
      this.$http.get(url, this.getAuthConfig()).then((results) => {
        this.processing = results.data;
        this.currency = this.processing.currency === 'usd' ? '$' : '€';
      });
    },

    showAddApplication: function() {
      this.newApplicationShown = true;
    },

    openModalAddApplication: function() {
      this.$modal.show('add-application')
    },

    triggerFirstTab: function() {
      this.refs.homeclick.click();
    },

    orderedInvoices: function(invoices) {
      var prop = this.user.autocredits ? "date" : "createdAt"
      return invoices.sort((a, b) => new Date(a[prop]) - new Date(b[prop])).reverse()
    },

    orderedPayments: function(payments) {
      var prop = "creation_date"
      return payments.sort((a, b) => new Date(a[prop]) - new Date(b[prop])).reverse()
    },

    markVerifyIfRequired: function() {
      if (this.user.lists_only_person_with_verified_email) {
        this.user.lists_verify_email = true;
      } else {
        this.user.lists_verify_email = false;
      }
      this.updateEntryCost();
    },

    markPersonalEmailIfRequired: function() {
      if (this.user.lists_only_person_with_personal_email) {
        this.user.lists_enrichment_personal_email = true;
      } else {
        this.user.lists_enrichment_personal_email = false;
      }
      this.updateEntryCost();
    },

    markPersonalPhoneIfRequired: function() {
      if (this.user.lists_only_person_with_personal_phone) {
        this.user.lists_enrichment_personal_phone = true;
      } else {
        this.user.lists_enrichment_personal_phone = false;
      }
      this.updateEntryCost();
    },

    markBusinessTypeIfRequired: function() {
      if (this.user.lists_only_person_with_business_type) {
        this.user.lists_enrichment_business_type = true;
      } else {
        this.user.lists_enrichment_business_type = false;
      }
      this.updateEntryCost();
    },

    updateEntryCost: function() {
      this.list_entry_cost = this.user.lists_price_per_person;

      if (this.user.lists_enrichment_web_visits) {
        this.list_entry_cost = this.list_entry_cost + 100;
      }

      if (this.user.lists_enrichment_personal_email) {
        this.list_entry_cost = this.list_entry_cost + 200;
      }

      if (this.user.lists_enrichment_personal_phone) {
        this.list_entry_cost = this.list_entry_cost + 200;
      }

      if (this.user.lists_verify_email) {
        this.list_entry_cost = this.list_entry_cost + 50;
      }

      if (this.user.lists_enrichment_business_type) {
        this.list_entry_cost = this.list_entry_cost + 750;
      }
    },

    filterInvoices: function() {
      var that = this
      var filtered = this.orderedInvoices(this.user.invoices_details.filter(function(i) {
        return that.invoice_year === i.year
      }))
      this.invoices = filtered
    },

    filterPayments: function() {
      var that = this
      var filtered = this.orderedPayments(this.paymentsOriginal.filter(function(i) {
        return that.payment_year === i.year
      }))
      this.payments = filtered
    },

    calculateInvoiceYears: function() {
      var that = this
      that.invoice_years = []
      that.invoices.forEach(function(i) {
        if (that.invoice_years.indexOf(i.year) === -1) {
          that.invoice_years.push(i.year)
        }
      })
      that.invoice_years.sort().reverse()
      this.invoice_year = that.invoice_years.length > 0 ? that.invoice_years[0] : "";
      this.filterInvoices()
    },

    calculatePaymentYears: function() {
      var that = this
      that.payment_years = []
      that.paymentsOriginal.forEach(function(i) {
        if (that.payment_years.indexOf(i.year) === -1) {
          that.payment_years.push(i.year)
        }
      })
      that.payment_years.sort().reverse()
      this.payment_year = that.payment_years.length > 0 ? that.payment_years[0] : "";
      this.filterPayments()
    },

    cardValidate: function() {

      if (this.card.lastname && this.card.number && this.card.month && this.card.year && this.card.cvv2) {
        if (this.card.number.length >= 13 && this.card.number.length <= 19) {
          this.CardValid = false;

          if (this.card.cvv2.length == 3) {
            this.CardValid = false;
          } else {
            this.CardValid = true;
          }
        } else {
          this.CardValid = true;
        }

      }

    },

    removeSource: function(index) {
      this.user.allowed_sources.splice(index, 1);
      this.hasToBeSaved();
    },

    addSource: function() {
      this.user.allowed_sources.push(this.newSource);
      this.newSource = "";
      this.hasToBeSaved();
    },

    uploadFile: function() {
      this.files = this.$refs.file.files[0];
      if (this.files) {
        var file = this.files;
        this.isError = false;
        this.isProcessing = true;
        let formData = new FormData();
        formData.append('file', file);
        var url = '/api/v2/upload-doc';
        this.$http.post(url, formData, this.getAuthConfig()).then(() => {
          this.getUserDetails();
        });
      }
    },

    sendInvitations: function() {
      this.loading = true
      var formData = {
        emails: this.emails.split(",").map(function(e) {
          return e.trim();
        })
      }
      var url = '/api/v2/invitations';
      this.$http.post(url, formData, this.getAuthConfig()).then((results) => {
        this.loading = false
        this.emails = ""
        this.invitations = results.data
        this.sent = results.data.filter(function(i) {
          return i.result === true
        }).length > 0
      })
    },

    onCopy: function() {
      this.$toast.success(this.$t('invite-link-copied'));
    },

    onCopyCode: function() {
      this.$toast.success(this.$t('code-copied'));
    },

    setSection: function(section) {
      this.section = section
      if (!this.isCurrentRoute('/settings/' + section)) {
        this.$router.push("/settings/" + section)
      }

      if (section == 'profile') {
        this.setProfileSection('data')
      }
    },

    viewInvoice: function(year, number) {
      this.section = 'invoice';
      var url = this.$apiBase + '/api/v1/' + this.user.apiKey + '/invoices/' + year + '/' + number;
      //console.log(url);
      var that = this;
      this.$http.get(url, this.getAuthConfig()).then((results) => {
        that.selected_invoice_year = year;
        that.selected_invoice_number = number;
        that.invoice_html = results.data;
      })
    },

    close: function() {
      this.invoice_html = '';
      this.selected_invoice_year = '';
      this.selected_invoice_number = '';
      this.section = 'billing';
    },

    print: function() {
      var pdfFrame = window.frames["invoice"];
      pdfFrame.focus();
      pdfFrame.print();
    },

    downloadBlacklist: function() {
      var url = '/api/v2/blacklist/download';
      this.$http.get(url, this.getauthConfig()).then((results) => {
        var link = document.createElement("a");
        link.setAttribute("href", "data:" + results.data);
        link.setAttribute("download", "blacklist.csv");
        document.body.appendChild(link); // Required for FF
        link.click();
      });
    },

    uploadBlacklist: function() {
      console.log("upload blacklist");
    },

    redirect(route) {
      this.$router.push(route);
    },

    hasCompletedFiscalDataCheck: function() {
      return this.user && this.user.company &&
        this.user.company.address && this.user.company.address.length > 0 &&
        this.user.company.cif && this.user.company.cif.length > 0 &&
        this.user.company.zipcode && this.user.company.zipcode.length > 0 &&
        this.user.company.city && this.user.company.city.length > 0 &&
        this.user.country_code.length > 0 &&
        this.user.company.name && this.user.company.name.length > 0 &&
        this.user.company.phone && this.user.company.phone.length > 0 &&
        this.user.company.province && this.user.company.province.length > 0;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
@import '../../assets/css/global.css';
@import '../../assets/css/settings.css';
</style>
