<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="pb-4">
        <a v-on:click="back">
          {{ $t("back") }}
        </a>
      </div>
      <div v-if="loading" class="text-center">
        <i class="fa fa-spinner fa-spin fa-3x"></i>
      </div>
      <div class="text-left" style="background-color: #fff; padding: 20px" v-else>
        <h4>{{ $t("settings") }}</h4>
        <div>
          <h5 class="pt-4">1. {{ $t("table-input") }} 
            <a href="#" @click.prevent="setSection('input')" class="gap-x-2" v-if="section !== 'input'">
            +
            </a>
          </h5>
          <div class="pt-2 pb-2" v-if="section === 'input'">
            <h6>
              <i>{{ $t("table-configured-data-sources") }}</i>
            </h6>
            <p>{{ $t("table-configured-data-sources-description") }}</p>
            <div class="row smry-status no_side_margin" style="overflow-x: auto;">
              <table class="table list-table text-xs">
                <thead>
                  <tr>
                    <th>{{ $t("table-source-type") }}</th>
                    <th>{{ $t("table-source-config") }}</th>
                    <th class="text-center">{{ $t("table-source-sync") }}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(source, index) in existingDataSources" :key="index">
                    <td>{{ $t("source-" + source.type) }}</td>
                    <td>
                      {{ source.config.host }}:{{ source.config.port }}/{{
                        source.config.database
                      }}/{{ source.config.table
                      }}{{
                        source.config.columns.length
                          ? " (" + source.config.columns.join(", ") + ")"
                          : ""
                      }}
                    </td>
                    <td class="text-center">
                      {{ $t("table-every-" + source.sync_every) }}
                    </td>
                    <td>
                      <button
                        class="btn btn-danger"
                        @click="deleteDataSource(index)"
                      >
                        <i class="fa fa-trash"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div>
              <div class="pt-2 pb-2">
                <h6>
                  <i>{{ $t("table-add-more-data-sources") }}</i>
                </h6>
                <p>{{ $t("table-add-more-data-sources-description") }}</p>
              </div>

              <div class="d-flex flex-wrap align-items-center pt-2 w-100">
                <div class="w-100">
                  {{ $t("table-input-chosen") }}:
                  <strong>{{ $t("source-" + settings.input.type) }}</strong>
                </div>
                <div class="w-100">
                  <div class="d-flex flex-wrap align-items-center">
                    {{ $t("table-available-methods") }}:
                    <a
                      v-for="source in sourceNames.filter(
                        (source) => source.key !== settings.input.type
                      )"
                      :key="source.key"
                      href="#"
                      @click.prevent="settings.input.type = source.key"
                      class="gap-x-2"
                    >
                      {{ $t("source-" + source.key) }}
                    </a>
                  </div>
                </div>
              </div>
              <div>
                <div>
                  <InputFile
                    v-if="settings.input.type === 'upload'"
                    :settings="settings"
                  />
                  <InputUrl
                    v-if="settings.input.type === 'url'"
                    :settings="settings"
                  />
                  <InputS3
                    v-if="settings.input.type === 's3'"
                    :settings="settings"
                  />
                  <InputSftp
                    v-if="settings.input.type === 'sftp'"
                    :settings="settings"
                  />
                  <InputMongo
                    v-if="settings.input.type === 'mongo'"
                    :settings="settings"
                  />
                  <InputMysql
                    v-if="settings.input.type === 'mysql'"
                    :settings="settings"
                  />
                  <InputPostgres
                    v-if="settings.input.type === 'postgres'"
                    :settings="settings"
                  />
                  <InputApiExternal
                    v-if="settings.input.type === 'api-external'"
                    :settings="settings"
                  />
                  <InputApi
                    v-if="settings.input.type === 'api'"
                    :settings="settings"
                  />

                  <div v-if="isAllowedRepeatByInputType()">
                    <div class="form-group row mt-2">
                      <div :for="'columns'" class="col-sm-2 text-left">
                        {{ $t("table-synchronization") }}
                      </div>
                      <div class="col-sm-10">
                        <!-- <select v-model="settings.input.properties.sync_every">
                            <option v-for="item in every_data_select" :key="item.key" :value="item.key">{{ $t("table-sync-every-" + item.key) }}</option>
                          </select> -->
                        {{
                          $t("table-every-" + settings.input.properties.sync_every)
                        }}
                      </div>
                      <small class="col-sm-10 offset-sm-2">{{
                        $t("table-sync-every-upgrade")
                      }}</small>
                    </div>
                  </div>
                </div>
              </div>

              <div class="pt-4">
                <button
                  type="button"
                  class="btn btn-primary"
                  v-if="settings.input.type !== 'api' && settings.input.type !== 'upload'"
                  v-on:click="addDataSource"
                  disabled="true"
                >
                  {{ $t("add") }}
                </button>            
              </div>
            </div>

          </div>
        </div>
        <div>
          <h5 class="pt-4">2. {{ $t("table-actions") }}</h5>
          <p class="pt-2 pb-4">{{ $t("table-actions-description") }}</p>
          <div v-for="(action, index) in actions" :key="index">
            <h6 class="font-italic">
              {{ $t("table-action-" + action.name) }}
              <a href="#" @click.prevent="setSection(action.name)" class="gap-x-2" v-if="section !== action.name">
                +
              </a>
            </h6>
            <p class="pt-2 pb-4">
              {{ $t("table-action-" + action.name + "-description") }}
            </p>
            <div v-if="section === action.name">
              <div v-if="action.showFields">
                <div class="table-responsive">
                  <table class="table">
                    <thead>
                      <tr>
                        <th class="col-lg-2">{{ $t("table-field") }}</th>
                        <th class="col-lg-6">{{ $t("table-tool") }}</th>
                        <th class="col-lg-4">{{ $t("table-when") }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="field in fields" :key="field.name">
                        <td class="col-lg-2">{{ field.name }}</td>
                        <td class="col-lg-8">
                          <select
                            v-model="
                              settings.actions[action.name].fields[field.name]
                                .tool
                            "
                            :disabled="
                              getToolsByField(action.name, field.name).length ===
                              0
                            "
                            v-on:change="
                              $forceUpdate();
                              updateToolOutputProperties(action.name, field.name);
                            "
                            class="form-control"
                          >
                            <option value="">{{ $t("select-processor") }}</option>
                            <option
                              v-for="tool in getToolsByField(
                                action.name,
                                field.name
                              )"
                              :key="tool.key"
                              :value="tool.key"
                            >
                              {{ tool.description }}
                            </option>
                          </select>
                          <div
                            v-if="
                              settings.actions[action.name].fields[field.name]
                                .tool.length
                            "
                            class="pt-4"
                          >
                            <div class="pb-2">
                              <strong>{{ $t("price") }}:</strong>
                              {{
                                getToolsByField(action.name, field.name).find(
                                  (tool) =>
                                    tool.key ===
                                    settings.actions[action.name].fields[
                                      field.name
                                    ].tool
                                ).cost / 10
                              }}€ {{ $t("cpt") }}
                            </div>
                            <h7
                              ><strong>{{ $t("output-params") }}</strong></h7
                            >
                            
                            <table class="table">
                              <thead>
                                <tr>
                                  <th>{{ $t("table-tool-output-property") }}</th>
                                  <th>{{ $t("table-tool-assigned-column") }}</th>
                                  <th>{{ $t("table-tool-property-save") }}</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="(item, index) in settings.actions[
                                    action.name
                                  ].fields[field.name].toolOutputProperties"
                                  :key="item.name"
                                >
                                  <td class="text-xs">{{ item.name }}</td>
                                  <td class="text-xs">
                                    {{
                                      settings.actions[action.name].fields[
                                        field.name
                                      ].toolOutputProperties[index].table_field
                                    }}
                                  </td>
                                  <td class="text-center">
                                    <input
                                      type="checkbox"
                                      v-model="
                                        settings.actions[action.name].fields[
                                          field.name
                                        ].toolOutputProperties[index].save
                                      "
                                      checked
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </td>
                        <td class="col-lg-2">
                          <select
                            v-model="
                              settings.actions[action.name].fields[field.name]
                                .when
                            "
                            :disabled="
                              settings.actions[action.name].fields[field.name]
                                .tool.length === 0
                            "
                            class="form-control"
                          >
                            <option value="">{{ $t("table-every-never") }}</option>
                            <option
                              v-for="item in every_tool_select"
                              :key="item.key"
                              :value="item.key"
                            >
                              {{ $t("table-every-" + item.key) }}
                            </option>
                          </select>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div v-else>
                <div class="table-responsive">
                  <table class="table">
                    <thead>
                      <tr>
                        <th class="col-lg-2">{{ $t("table-field") }}</th>
                        <th class="col-lg-6">{{ $t("table-key") }}</th>
                        <th class="col-lg-4">{{ $t("table-order") }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="field in fields" :key="field.name">
                        <td class="text-xs">{{ field.name }}</td>
                        <td>
                          <input
                            type="checkbox"
                            v-model="
                              settings.actions[action.name].fields[field.name].key
                            "
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            v-model="
                              settings.actions[action.name].fields[field.name]
                                .order
                            "
                            class="form-control"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div>{{ $t("table-deduplicate-criteria") }}</div>
                <select
                  v-model="settings.actions['deduplicate'].criteria"
                  class="form-control col-lg-2"
                >
                  <option value="" selected>
                    {{ $t("table-select-criteria") }}
                  </option>
                  <option value="best_sort">
                    {{ $t("table-deduplicate-criteria-best-sort") }}
                  </option>
                  <option value="most_recent">
                    {{ $t("table-deduplicate-criteria-most-recent") }}
                  </option>
                  <option value="least_recent">
                    {{ $t("table-deduplicate-criteria-least-recent") }}
                  </option>
                </select>
                <div class="pt-4">
                  <p>{{ $t("table-deduplicate-criteria-description") }}</p>
                  <ul>
                    <li>
                      <strong
                        >{{ $t("table-deduplicate-criteria-best-sort") }}:</strong
                      >
                      {{ $t("table-deduplicate-criteria-best-sort-description") }}
                    </li>
                    <li>
                      <strong
                        >{{
                          $t("table-deduplicate-criteria-most-recent")
                        }}:</strong
                      >
                      {{
                        $t("table-deduplicate-criteria-most-recent-description")
                      }}
                    </li>
                    <li>
                      <strong
                        >{{
                          $t("table-deduplicate-criteria-least-recent")
                        }}:</strong
                      >
                      {{
                        $t("table-deduplicate-criteria-least-recent-description")
                      }}
                    </li>
                  </ul>
                </div>
              </div>
              </div>
          </div>
        </div>
        <div>
          <h5 class="pt-4">3. {{ $t("table-events") }}
            <a href="#" @click.prevent="setSection('events')" class="gap-x-2" v-if="section !== 'events'">
              +
            </a>
          </h5>
          <p class="pt-2 pb-4">{{ $t("table-events-description") }}</p>
          <div v-if="section === 'events'">
            <div class="form-group row mt-2">
              <div class="col-sm-2 text-left">
                {{ $t("table-events-record-updated") }}
              </div>
              <div class="col-sm-10">
                <input
                  type="text"
                  v-model="settings.events.updated.apiUrl"
                  :placeholder="$t('api-url-placeholder')"
                  class="form-control"
                />
                <small class="text-muted">{{ $t("table-events-record-updated-help") }}</small>
                <pre class="text-muted text-xs">
{
  "id": 1,
  "data": {
    "ip": "22.12.12.12",
    "ip_extra": {
      "get-location-by-ip": {
        "city": {
          "from": "",
          "to": "Barcelona"
        },
        "zipcode": {
          "from": "",
          "to": "08001"
        },
        "county": {
          "from": "",
          "to": "Barcelona"
        },
        ...
      }
    },
  },
  "timestamp": "2021-01-01T00:00:00Z"
}
                </pre>
              </div>
            </div>

            <div class="form-group row mt-2">
              <div class="col-sm-2 text-left">
                {{ $t("table-events-record-deleted") }}
              </div>
              <div class="col-sm-10">
                <input
                  type="text"
                  v-model="settings.events.deleted.apiUrl"
                  :placeholder="$t('api-url-placeholder')"
                  class="form-control"
                />
                <small class="text-muted">{{ $t("table-events-record-deleted-help") }}</small>
                <pre class="text-muted text-xs">
{
  "id": 1,
  "data": {
    "name": "John Doe",
    "age": 30,
    "ip": "22.12.12.12",
    "ip_extra": {
      "get-location-by-ip": {
        "city": "Barcelona"
      }
    }
  },
  "timestamp": "2021-01-01T00:00:00Z"
}
                </pre>
              </div>
            </div>
          </div>

          <div>
            <h5 class="pt-4">4. {{ $t("table-export") }}
              <a href="#" @click.prevent="setSection('export')" class="gap-x-2" v-if="section !== 'export'">
                +
              </a>
            </h5>
            <p class="pt-2 pb-4">{{ $t("table-export-description") }}</p>
            <div v-if="section === 'export'">
              <ul>
                <li class="pb-2">
                    <strong>{{ $t('table-export-database') }}:</strong> {{$t('table-export-database-description')}}
                    <br>
                    <input type="text" class="form-control" placeholder="mysql://root:password@localhost:3306/uproc_tables">
                </li>
                <li class="pb-2">
                    <strong>{{ $t('table-export-csv') }}:</strong> {{$t('table-export-csv-description')}}
                    <br>
                      URL:
                      https://localhost:3000/api/v1/tables/6/data/csv
                </li>
                <li class="pb-2">
                    <strong>{{ $t('endpoint-export-paginated') }}:</strong> {{$t('endpoint-export-paginated-description')}}
                    <pre class="pt-2 pb-2 text-xs text-muted">
curl -X GET http://localhost:3000/api/v1/tables/6/data?start_row=0&rows_per_page=100 \
-H 'Authorization: Bearer {{token}}' \
-H 'Content-Type: application/json'

Output:
{
    "data": [
        {
            "id": 11,
            "name": "John Doe",
            "age": 30, 
            "ip": "22.12.12.12.12"
            "ip_extra": {
              "get-location-by-ip": {
                "city": "Barcelona",
                "zipcode": "08001",
                "county": "Barcelona"
                ...
              }
            }
        },
        ...
    ],
    "page": 1,
    "pages": 1800,
    "total": 180000
}

                    
                    </pre>
                </li>
                
            </ul>

            </div>
          </div>

          <div class="pt-4">
            <button
              type="button"
              class="btn btn-primary"
              v-on:click="saveSettings()"
            >
              {{ $t("save") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputFile from "./types/File.vue";
import InputUrl from "./types/Url.vue";
import InputS3 from "./types/S3.vue";
import InputSftp from "./types/Sftp.vue";
import InputMongo from "./types/Mongo.vue";
import InputMysql from "./types/Mysql.vue";
import InputPostgres from "./types/Postgres.vue";
import InputApi from "./types/Api.vue";
import InputApiExternal from "./types/ApiExternal.vue";

export default {
  name: "settings",
  data() {
    return {
      loading: true,
      section: 'input',//normalize, validate, enrich, deduplicate, output

      tools: {
        normalize: [],
        validate: [],
        enrich: [],
      },

      sourceNames: [
        { key: "api" },
        { key: "api-external" },
        { key: "upload" },
        { key: "mongo" },
        { key: "mysql" },
        { key: "postgres" },
        { key: "s3" },
        { key: "sftp" },
        { key: "url" },
      ],

      existingDataSources: [
        {
          type: "mysql",
          name: "MySQL production",
          config: {
            host: "localhost",
            port: 3306,
            database: "mydb",
            table: "mytable",
            columns: ["name", "age"],
          },
          sync_every: "1day",
        },
        {
          type: "mongo",
          name: "MongoDB production",
          config: {
            host: "localhost",
            port: 27017,
            database: "mydb",
            table: "mycollection",
            columns: ["name", "age"],
          },
          sync_every: "1day",
        },

        {
          type: "s3",
          name: "S3 production",
          config: {
            host: "s3.amazonaws.com",
            port: 443,
            database: "mybucket",
            table: "users.csv.gz",
            columns: ["name", "age"],
          },
          sync_every: "1day",
        },

        {
          type: "sftp",
          name: "SFTP production",
          config: {
            host: "sftp.example.com",
            port: 22,
            database: "myfolder",
            table: "users.csv.gz",
            columns: ["name", "age"],
          },
          sync_every: "1day",
        },
      ],

      actions: [
        { name: "normalize", selected: false, showFields: true },
        { name: "validate", selected: false, showFields: true },
        { name: "enrich", selected: false, showFields: true },
        { name: "deduplicate", selected: false, showFields: false },
      ],
      fields: [
        //key
        {
          name: "email",
        },

        //personal
        {
          name: "nif",
        },
        {
          name: "firstname",
        },
        {
          name: "lastname",
        },
        {
          name: "birthdate",
        },
        {
          name: "phone",
        },
        {
          name: "mobile",
        },

        //company
        // {
        //   "name": "company"//name, companyname, organization
        // },
        // {
        //   "name": "vat"//vatid, vatnumber, cif
        // },
        // {
        //   "name": "website"//url, domain
        // },
        // {
        //   "name": "industry"
        // },
        // {
        //   "name": "employees"
        // },
        // {
        //   "name": "founded"
        // },

        //geo
        {
          name: "address",
        },
        {
          name: "city",
        },
        {
          name: "state",
        },
        {
          name: "zip",
        },
        {
          name: "country",
        },
        {
          name: "ip",
        },
      ],
      settings: {
        input: {
          type: "upload",
          properties: {
            sync_every: "1day",
          },
        },
        actions: {
          normalize: {
            fields: {},
          },
          validate: {
            fields: {},
          },
          enrich: {
            fields: {},
          },
          deduplicate: {
            fields: {},
            criteria: "",
          },
        },

        events: {
          updated: {
            apiUrl: "",
            payload: "",
          },
          deleted: {
            apiUrl: "",
            payload: "",
          },
          
        },
      },

      every_data_select: [
        {
          key: "15min",
        },
        {
          key: "30min",
        },
        {
          key: "1hour",
        },
      ],

      every_tool_select: [
        {
          key: "once",
        },
        {
          key: "1day",
        },
        {
          key: "1week",
        },
        {
          key: "1month",
        },
        {
          key: "3months",
        },
      ],

      hasToBeSent: false,
      tableName: "",
    };
  },
  mounted() {
    this.tableName = this.$route.params.name;
    this.getProcessorsDetails();
    this.initActionsWithFields();
    this.initDeduplicateData();
    this.loading = false;
  },
  components: {
    InputFile,
    InputUrl,
    InputS3,
    InputSftp,
    InputMongo,
    InputMysql,
    InputPostgres,
    InputApi,
    InputApiExternal,
  },
  methods: {
    initDeduplicateData: function () {
      //field email marked as key
      this.settings.actions.deduplicate.fields.email.key = true;
    },
    initActionsWithFields: function () {
      this.actions.forEach((action) => {
        this.fields.forEach((field) => {
          this.settings.actions[action.name].fields[field.name] = {};
          if (action.name === "deduplicate") {
            this.settings.actions[action.name].fields[field.name].key = false;
            this.settings.actions[action.name].fields[field.name].order = 0;
          } else {
            this.settings.actions[action.name].fields[field.name].tool = "";
            this.settings.actions[action.name].fields[field.name].when = "";
          }
        });
      });
      //console.log(JSON.stringify(this.settings));
    },

    addDataSource: function () {
      this.$toast.success("Data source added");
    },

    addFilter: function () {
      this.settings.output.filters.push({
        field: "",
        condition: "",
        value: "",
      });
      this.$forceUpdate();
    },

    back: function () {
      this.$emit("change-subsection", "view");
    },

    isAllowedRepeatByInputType: function () {
      const allowedTypes = [
        "api-external",
        "mysql",
        "mongo",
        "sftp",
        "s3",
        "url",
      ];
      return allowedTypes.includes(this.settings.input.type);
    },

    saveSettings: function () {
      console.log(this.settings);
      this.back();
    },

    updateToolOutputProperties: function (action, field) {
      let tool = this.tools[action].find((tool) => {
        return tool.key === this.settings.actions[action].fields[field].tool;
      });

      //console.log(tool);

      if (tool) {
        this.settings.actions[action].fields[field].toolOutputProperties = [];
        if (!tool.output || tool.output.length === 0) {
          //TODO: this doesnt work
          this.settings.actions[action].fields[field].toolOutputProperties.push(
            {
              name: "result",
              table_field: field + `_extra.${tool.key}.result`,
              save: true,
            }
          );
        } else {
          tool.output[0].properties.forEach((prop) => {
            this.settings.actions[action].fields[field].toolOutputProperties.push({
              name: prop.name,
              table_field: field + `_extra.${tool.key}.${prop.name}`,
              save: true,
            });
          });
        }
      }
    },
    handleFileUpload: function (e) {
      console.log(e.target.files[0]);
    },

    setSection: function (section) {
      this.section = section;
    },
    
    setSubsection: function (subsection) {
      this.subsection = subsection;
    },

    getConfigAuth: function () {
      return {
        headers: {
          Authorization:
            "Basic " +
            btoa(localStorage.configEmail + ":" + localStorage.token),
          "Content-Type": "application/json",
        },
      };
    },

    filterToolsByType: function (tools, type) {
      let filteredTools = [];
      if (type === "normalize") {
        filteredTools = tools.filter((tool) => {
          return (
            tool.type === "normalize" ||
            tool.key.includes("normalize") ||
            tool.key.includes("clean") ||
            tool.key.includes("parse")
          );
        });
      } else if (type === "validate") {
        filteredTools = tools.filter((tool) => {
          return (
            tool.type === "check" ||
            tool.key.includes("check") ||
            tool.key.includes("validate")
          );
        });
      } else if (type === "enrich") {
        filteredTools = tools.filter((tool) => {
          return (
            tool.type === "get" ||
            tool.key.includes("get") ||
            tool.key.includes("enrich")
          );
        });
      }

      return filteredTools;
    },

    getRestrictedToolsForTables: function (tools) {
      let filtered = tools
        .filter((tool) => {
          return tool.params.length === 1;
        })
        .filter((tool) => {
          return tool.public;
        });

      //sort case insensitive
      filtered.sort((a, b) => {
        return a.description
          .toLowerCase()
          .localeCompare(b.description.toLowerCase());
      });

      return filtered;
    },

    getProcessorsDetails: function (callback = null) {
      var url = "/json/" + this.$locale + "/processors.json";
      this.$emit("isLoading", true);
      console.log(url);
      this.$http({
        method: "GET",
        url: url,
        baseURL: "/",
      }).then((results) => {
        let processors = this.getRestrictedToolsForTables(
          results.data.processors
        );
        this.tools.normalize = this.filterToolsByType(processors, "normalize");
        this.tools.validate = this.filterToolsByType(processors, "validate");
        this.tools.enrich = this.filterToolsByType(processors, "enrich");
        this.$emit("isLoading", false);
        if (callback) {
          callback();
        }
      });
    },

    getToolsByField: function (action, field) {
      return this.tools[action].filter((tool) => {
        return tool.params.some(
          (param) => param.name == field || param.name.indexOf(field) === 0
        );
      });
    },

    hasToBeSaved: function () {
      if (JSON.stringify(this.user) === JSON.stringify(this.FromServerUser)) {
        this.hasToBeSent = false;
      } else {
        this.hasToBeSent = true;
      }
    },

    getUserDetails: function () {
      var url = "/api/v2/profile";
      this.$http.get(url, this.getConfigAuth()).then((results) => {
        this.user = results.data;
      });
    },
  },
};
</script>
