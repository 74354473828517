<template>
<div>
  <div v-if="loading">
    <div class="animated loader"></div>
  </div>
  <div class="container pt-2">
    <div class="row">
      <div class="col-12">
        <p class="logo text-center">
          <a v-bind:href="'https://uproc.io'" target="_blank">
            <svg width="56" height="66" viewBox="0 0 56 66" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M33.0627 7.05834C33.0627 17.0093 27.3645 22.5161 17.755 22.5161C16.0433 22.5161 14.4558 22.3414 13.0024 21.998C15.4055 27.8663 21.172 32 27.9036 32C36.7934 32 44 24.791 44 15.8982C44 8.81039 39.422 2.79222 33.0627 0.641137V7.05834ZM12 13.399C13.293 15.0897 15.2718 15.8499 17.8033 15.8499C22.5839 15.8499 25.3364 13.1931 25.3364 6.7685V0C18.4799 1.09935 13.0699 6.53048 12 13.399Z"
                fill="#0300e2" />
              <path
                d="M8.54966 46.316V51.104C8.54966 52.886 7.61366 53.714 6.31766 53.714C5.07566 53.714 4.35566 52.994 4.35566 51.374V46.316H1.54766V51.788C1.54766 54.776 3.25766 56.144 5.68766 56.144C6.87566 56.144 7.95566 55.694 8.69366 54.848V56H11.3577V46.316H8.54966ZM19.6111 43.4H14.1571V56H17.0731V52.526H19.6111C22.9771 52.526 25.0831 50.78 25.0831 47.972C25.0831 45.146 22.9771 43.4 19.6111 43.4ZM19.4491 50.15H17.0731V45.776H19.4491C21.2311 45.776 22.1311 46.586 22.1311 47.972C22.1311 49.34 21.2311 50.15 19.4491 50.15ZM29.6489 47.594V46.316H26.9669V56H29.7749V51.428C29.7749 49.574 30.8009 48.728 32.3489 48.728C32.5649 48.728 32.7449 48.746 32.9789 48.764V46.172C31.4849 46.172 30.3329 46.658 29.6489 47.594ZM39.1686 56.144C42.2466 56.144 44.4786 54.074 44.4786 51.158C44.4786 48.242 42.2466 46.172 39.1686 46.172C36.0906 46.172 33.8406 48.242 33.8406 51.158C33.8406 54.074 36.0906 56.144 39.1686 56.144ZM39.1686 53.84C37.7646 53.84 36.6846 52.832 36.6846 51.158C36.6846 49.484 37.7646 48.476 39.1686 48.476C40.5726 48.476 41.6346 49.484 41.6346 51.158C41.6346 52.832 40.5726 53.84 39.1686 53.84ZM51.0356 56.144C53.0696 56.144 54.6716 55.244 55.3736 53.696L53.1956 52.508C52.6736 53.444 51.8996 53.84 51.0176 53.84C49.5956 53.84 48.4796 52.85 48.4796 51.158C48.4796 49.466 49.5956 48.476 51.0176 48.476C51.8996 48.476 52.6736 48.89 53.1956 49.808L55.3736 48.638C54.6716 47.054 53.0696 46.172 51.0356 46.172C47.8856 46.172 45.6356 48.242 45.6356 51.158C45.6356 54.074 47.8856 56.144 51.0356 56.144Z"
                fill="#0300e2" />
            </svg>
          </a>
        </p>
        <h3 class="welcome text-center">{{$t('signin')}}</h3>
        <p class="commit">{{$t('signin-subtitle')}}</p>
        <div class="row">
          <div class="col-12 d-flex flex-row justify-content-center">
            <div class="content-box content-box-signin">
              <form @submit.prevent="submit">
                <div class="formatting">
                  <label class="has-float-label">
                    <input type="text" :disabled="loading || logged" name="email" placeholder="" autocomplete="off" @focus="flag_email = true" @blur="flag_email = false" v-model.trim="$v.email.$model" v-on:keyup="resetErrors()" :class="[isEmailValid()]" required="" v-on:click="$event.target.select()">
                    <span>{{$t('email')}}</span>
                    <div v-if="flag_email" class="icon-inside icon-with">
                      <font-awesome-icon icon="envelope" />
                    </div>
                    <div v-else class="icon-inside icon-without">
                      <font-awesome-icon icon="envelope" />
                    </div>
                    <div class="error" v-if="!$v.email.required">{{$t('field-required')}}</div>
                    <div class="error" v-if="!$v.email.email">{{$t('field-email')}}</div>
                  </label>
                </div>
                <div class="formatting final-pass">
                  <label class="has-float-label">
                    <input type="password" :disabled="loading || logged" name="pass" placeholder="" autocomplete="off" @focus="flag_pass = true" @blur="flag_pass = false" v-on:keyup="resetErrors()" v-model="$v.password.$model" :class="[checkPassword()]" required="">
                    <span>{{$t('password')}}</span>
                    <div v-if="flag_pass" class="icon-inside icon-with">
                      <font-awesome-icon icon="key" />
                    </div>
                    <div v-else class="icon-inside icon-without">
                      <font-awesome-icon icon="key" />
                    </div>
                    <div class="error" v-if="!$v.password.required">{{$t('field-required')}}</div>
                  </label>
                </div>
                <p>
                  <!--vue-recaptcha  ref="recaptcha" :sitekey="recaptcha_key" :loadRecaptchaScript="recaptcha_key.length > 0" @verify="onVerify" @error="onError" @expired="onExpired"></vue-recaptcha-->
                  <button type="submit" v-bind:class="(email_error || password_error || loading || logged) ? 'btn-disable btn-forgot' : 'btn-forgot'" @click="postSubmit" :disabled="email_error || password_error || recaptcha_error || loading">
                    <span v-if="loading">{{$t('sending')}}</span>
                    <span v-if="!loading">{{$t('enter')}}</span>
                  </button>
                </p>

                <div v-if="response_message != ''">
                  <p v-bind:class="response_result ? 'alert alert-success text-center text-success mt-4 mb-4' : 'alert alert-danger text-center text-danger mt-4'">
                    <span v-if="!response_result">{{$t('signin-ko')}} {{response_message}}</span>
                    <span v-else v-html="$t('signin-ok')"></span>
                  </p>
                </div>
                <p class="forgot text-center">
                  <router-link :to="'forgot'">{{$t('remember-password')}}</router-link>
                </p>
              </form>
            </div>
            <!--div v-if="logged" class="col-lg-6 alert alert-info text-center text-info mt-4 mb-4">
              <div class="text-center">
                <span v-html="$t('signin-ok')"></span>
                <br>
                <div class="spinner-border" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            </div-->
          </div>
        </div>
        <h3 class="no-account">{{$t('signup-user-new')}}</h3>
        <h5 class="signed" v-html="$t('signup-user-new-description')"></h5>
      </div>
    </div>
  </div>
  <Footer />
</div>
</template>

<script>
//import VueRecaptcha from 'vue-recaptcha';
import i18n from '@/plugins/i18n';
import Footer from '@/components/Footer.vue'
import { required, email } from 'vuelidate/lib/validators'

export default {
  name: 'Login',
  components: {
    Footer,
    //VueRecaptcha
  },
  data() {
    return {
      loading: false,
      flag_email: false,
      flag_pass: false,
      email_error: true,
      password_error: true,
      email: '',
      password: '',
      response_result: true,
      response_message: '',
      //reg: /^(([^<>().,;:\s@"]+(\.[^<>().,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      reg: /^(([^<>().,;:\s@"]+(\.[^<>().,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      //recaptcha_key: '6Ldc6-IUAAAAAB3XjLtadKHOnFaxuy4jEI20CsNv',//invisible captcha
      recaptcha_key: '6LfM6uIUAAAAAI3xuETA43P-qiyJL8JhMZWyFs8z',//checkbox
      recaptcha_error: false,
      logged: false,
      languages: [{
          flag: 'us',
          language: 'en',
          title: 'English | '
        },
        {
          flag: 'es',
          language: 'es',
          title: 'Español'
        }
      ],
    }
  },
  validations: {
    email: {
      required,
      email
    },
    password: {
      required
    }
  },
  mounted() {
    var email = localStorage && localStorage.configEmail && localStorage.configEmail !== 'undefined' ? localStorage.configEmail : "";
    if (this.$route.params.email && this.$route.params.email.length) {
      email = this.$route.params.email;
    }

    this.email = email;

    if (!window.grecaptcha) {
      //this.recaptcha_error = true;
    }

    //redirect to signup if no email
    if (!this.email) {
      //this.$router.push('/signup');
    }
    
  },
  methods: {
    getConfigAuth: function() {
      return {
        headers: {
          "Authorization": "Basic " + btoa(localStorage.configEmail + ":" + localStorage.token),
          "Content-Type": "application/json"
        }
      };
    },
    
    onError: function() {
      //error on recaptcha. Avoid it (china and offline)
      //console.log(response)
      //this.recaptcha_error = true;
    },

    onVerify: function (response) {
      if (response && response.length) {
        //console.log('Verify: ' + response)
        this.recaptcha_error = false;
      }
    },

    onExpired: function () {
      console.log('Expired')
    },

    resetRecaptcha () {
      //this.$refs.recaptcha.reset() // Direct call reset method
    },

    resetErrors: function() {
      this.email_error = false
      this.password_error = false
      this.loading = false
      this.response_result = false
      this.response_message = ""
    },

    isEmailValid: function() {
      return (this.email == "") ? "" : (this.reg.test(this.email)) ? this.email_error = false : this.email_error = true;
    },

    checkPassword: function() {
      return (this.password == "") ? this.password_error = true : this.password_error = false;
    },

    hasCompletedFiscalDataCheck: function() {
      return this.user && this.user.company &&
        this.user.company.address && this.user.company.address.length > 0 &&
        this.user.company.cif && this.user.company.cif.length > 0 &&
        this.user.company.zipcode && this.user.company.zipcode.length > 0 &&
        this.user.company.city && this.user.company.city.length > 0 &&
        this.user.country_code.length > 0 &&
        this.user.company.name && this.user.company.name.length > 0 &&
        this.user.company.phone && this.user.company.phone.length > 0 &&
        this.user.company.province && this.user.company.province.length > 0;
    },

    initDrift: function() {
      if (process.env.NODE_ENV === 'production') {
        this.$drift.config({
          locale: this.$i18n.locale.toLowerCase().indexOf("es") !== -1 ? "es" : "en",
          messages: {
            welcomeMessage: this.$t('drift-welcome'),
            awayMessage: this.$t('drift-away'),
            emailCaptureMessage: this.$t('drift-email'),
            thankYouMessage: this.$t('drift-thankyou')
          }
        });

        if (this.user) {
          var data = {
            name: this.user.name,
            email: this.user.email
          }
          if (this.user.company && this.user.company.phone.length) {
            data.phone = this.user.company.phone;
          }
          this.$drift.identify(this.user.email, data);
        }
      }
    },

    identifyPosthog: function() {
      if (this.user && this.user.email && window.posthog) {
        window.posthog.identify(this.user.email, {
          email: this.user.email,
          name: this.user.name,
          role: this.user.role,
          company: this.user.company.name,
          plan: this.user.plan,
        });
      }
    },

    getUser: function() {
      var url = '/api/v2/profile';
      this.$http.get(url, this.getConfigAuth()).then((results) => {
        this.user = results.data;
        if (this.user.role === 'admin') {
          localStorage.setItem('role', this.user.role);
        }
        this.initDrift();

        //var profileNeedsComplete = !this.hasCompletedFiscalDataCheck();
        //this.$store.commit('setNeedsComplete', profileNeedsComplete)

        
        setTimeout(()=>{
          this.identifyPosthog();
          localStorage.setItem('role', this.user.role)
          var signup_reason = this.user.signup_reason.replace(/ /g, "-").toLowerCase();
          localStorage.setItem('signupReason', signup_reason)
 
          if (this.$route.query && this.$route.query.redirect) {
            this.$router.push(this.$route.query.redirect);
          } else {
            if (this.user.role === 'admin') {
              this.$router.push('/admin');
            } else {
              this.$router.push('/welcome');
            }
          }
        }, 2000);
      });
    },

    postSubmit: function() {
      //this.resetRecaptcha()
      var data = {
        email: this.email,
        password: this.password
      }
      this.logged = false;
      this.$http.post('/api/v2/signin', data).then((results) => {
        this.loading = false
        this.response_result = results.data.result,
        this.response_message = results.data.message,
        localStorage.token = results.data.apiKey;

        if (results.data.apiKey) {
          this.logged = true;
          localStorage.configEmail = this.email;
          this.$i18n.locale = navigator.language.toLowerCase().indexOf("es") !== -1 ? "es" : "en"
          this.getUser()
        }
      });
    },

    submit: function() {
      this.loading = true
      //this.$refs.recaptcha.execute()
    },

    changeLocale: function(locale) {
      i18n.locale = locale;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import '../../assets/css/login_signup.css';
</style>
