<template>
  <div class="tab-content">
    <div id="home" class="tab-pane container active">
      <div v-if="section == 'add'">
        <Add v-on:change-section="setSection" />
      </div>
      <div v-else-if="section == 'list'">
        <div class="pb-4">
          <div class="row">
            <div class="col-lg-9">
              <a lass="btn btn-primary" v-on:click="setSection('add')">
                <font-awesome-icon icon="plus" size="lg" />
                &nbsp;
                {{ $t("tables-new") }}
              </a>
            </div>

            <!-- <div class="col-lg-3">
                <div class="select_period col-text mr-2">
                  <p class="text">{{ $t('select-period') }}:</p>
                </div>
                <div class="">
                    <select v-model="period" class="form-control" v-on:change="changePeriod(period)">
                    <option value="thisweek">{{ $t('select-period-thisweek') }}</option>
                    <option value="previousweek">{{ $t('select-period-previousweek') }}</option>
                    <option value="thismonth">{{ $t('select-period-thismonth') }}</option>
                    <option value="previousmonth">{{ $t('select-period-previousmonth') }}</option>
                    <option value="thisquarter">{{ $t('select-period-thisquarter') }}</option>
                    <option value="previousquarter">{{ $t('select-period-previousquarter') }}</option>
                    <option value="thisyear">{{ $t('select-period-thisyear') }}</option>
                    <option value="previousyear">{{ $t('select-period-previousyear') }}</option>
                    </select>
                </div>
              </div> -->
          </div>
        </div>

        <!-- <div class="row pt-4 pb-2">
              <div
                class="col-lg-12 col-md-12"
                v-if="tables.length > 0"
                v-html="
                  $t('list-pagination', {
                    startItems: tables.length,
                    finishItems: tables.length,
                  })
                "
              ></div>
          </div> -->

        <div
          class="col-lg-12 no_side_pad request"
          v-if="this.tables && this.tables.length"
        >
          <div class="page-nav">
            <div class="row no_side_margin">
              <div class="col-lg-2 col-sm-4 col-4 text-right">
                <div>
                  <a
                    :class="{ disabled: isFirstPage(), '': !isFirstPage() }"
                    v-on:click="movePrevious()"
                  >
                    <span class="nav-icon">←</span> {{ $t("previous") }}
                  </a>
                </div>
              </div>

              <div class="col-lg-8 col-sm-4 col-4 no_side_pad text-center">
                {{ $t("page") }} {{ this.currentPage + 1 }} /
                {{ this.totalPages }}
              </div>

              <div class="col-lg-2 col-sm-4 col-4 no_side_pad">
                <div>
                  <a
                    :class="{ disabled: isLastPage(), '': !isLastPage() }"
                    v-on:click="moveNext()"
                  >
                    {{ $t("next") }} <span class="nav-icon">→</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row smry-status no_side_margin list-scroller" style="overflow-x: auto">
          <table class="table table-striped list-table" style="margin-top: 0px">
            <thead class="thead-fixed">
              <tr>
                <th class="t-head">{{ $t("date") }}</th>
                <th class="t-head">{{ $t("date-update") }}</th>
                <th class="t-head">{{ $t("name") }}</th>
                <th class="t-head text-right">{{ $t("rows") }}</th>
                <th class="t-head text-right">{{ $t("cost") }}</th>
                <th class="t-head text-right">{{ $t("actions") }}</th>
              </tr>
            </thead>

            <tbody v-if="!this.tables.length">
              <tr>
                <td colspan="6" class="text-center table-col">
                  {{ $t("no-results") }}
                </td>
              </tr>
            </tbody>

            <tbody v-if="this.tables.length">
              <tr v-for="(table, index) in this.tables" v-bind:key="index">
                <td class="table-col">{{ table.date | formatDate }}</td>
                <td class="table-col">{{ table.lastUpdate | formatDate }}</td>
                <td class="table-col">
                  {{ table.name }}
                </td>
                <td class="table-col text-right">
                  {{ table.totalRows }}
                </td>
                <td class="table-col text-right">
                  {{ table.cost }}{{ currency }}
                </td>
                <td class="table-col text-right">
                  <a
                    class="pointer pull-right mr-2"
                    title="Delete table"
                    @click="deleteTable(table)"
                    ><font-awesome-icon icon="trash"
                  /></a>
                  <a class="pointer pull-right mr-2" title="Download table"
                  @click="downloadTable(table)"

                    ><font-awesome-icon icon="download"
                  /></a>
                  <a
                    class="pointer pull-right mr-2"
                    title="View table"
                    v-bind:href="'#/mydata/tables/' + table.id"
                    v-on:click="setSection('view')"
                    ><font-awesome-icon icon="list"
                  /></a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div v-else-if="section == 'view'">
        <ListView v-on:change-section="setSection"/>
      </div>
    </div>
  </div>
</template>

<script>
import Add from "./Add.vue";
import ListView from "./View.vue";

export default {
  name: "tables",
  data() {
    return {
      orderByField: "startDate",
      reverseSort: true,
      startDate: "",
      endDate: "",
      pendingRequest: false,
      section: "list",
      currentPage: 0,
      totalRecords: 5,
      totalPages: 1,
      totalRows: 1,
      totalRowsOk: 0,
      totalCost: 0,
      totalCredits: 0,
      totalSavings: 0,
      prevStartDate: null,
      prevEndDate: null,
      tables: [
        {
          id: 1,
          date: "2021-01-01",
          lastUpdate: "2021-08-01",
          name: "usuarios",
          totalRows: 100000,
          cost: 10.12,
        },
        // {
        //   id: 2,
        //   date: "2021-02-01",
        //   lastUpdate: "2021-07-01",
        //   name: "tabla productos",
        //   totalRows: 200000,
        //   cost: 20.57,
        // },
        // {
        //   id: 3,
        //   date: "2021-03-01",
        //   lastUpdate: "2021-09-01",
        //   name: "tabla ventas",
        //   totalRows: 150000,
        //   cost: 15.91,
        // },
        // {
        //   id: 4,
        //   date: "2021-04-01",
        //   lastUpdate: "2021-10-01",
        //   name: "tabla clientes",
        //   totalRows: 120000,
        //   cost: 12.35,
        // },
        // {
        //   id: 5,
        //   date: "2021-05-01",
        //   lastUpdate: "2021-11-01",
        //   name: "tabla proveedores",
        //   totalRows: 180000,
        //   cost: 18.79,
        // },
        {
          id: 6,
          date: "2024-05-01",
          lastUpdate: "2024-11-01",
          name: "negocios",
          totalRows: 1800000,
          cost: 180.79,
        },
      ],

      tablesRunning: false,
      countdownToRefresh: 30,
      secondsToRefresh: 30,
      loading: false,

      period: "thisweek",

      pendingJobs: false,
      currency: "€",
    };
  },
  mounted() {
    this.startDate = new Date(Date.now() + -60 * 24 * 3600 * 1000);
    this.endDate = new Date();
    //this.getTablesTrigger();
  },
  components: {
    Add,
    ListView,
  },
  methods: {
    getTablesTrigger: function () {
      this.period = "thisweek";
      this.currentPage = 0;
      this.totalPages = 0;
      this.getTables(true);
    },

    setSection: function (section) {
      console.log("section", section);
      this.section = section;
    },

    getConfigAuth() {
      return {
        headers: {
          Authorization:
            "Basic " +
            btoa(localStorage.configEmail + ":" + localStorage.token),
          "Content-Type": "application/json",
        },
      };
    },

    setTimeoutJobs: function () {
      setTimeout(() => {
        this.clearCountdown();
        this.getTables(true);
      }, 60000);
    },

    clearCountdown: function () {
      clearInterval(this.countdown);
      //clear all intervals
      for (var i = 1; i < 100; i++) {
        window.clearInterval(i);
      }
    },

    initCountdown: function () {
      this.countdownToRefresh = this.secondsToRefresh;
      this.countdown = setInterval(() => {
        this.countdownToRefresh--;
        if (this.countdownToRefresh === 0) {
          this.clearCountdown();
          this.getTables(true);
        }
      }, 1000);
    },

    changePeriod: function (period) {
      this.period = period;
      this.currentPage = 0;
      this.totalPages = 0;
      this.getTables(true);
    },

    getTables: function (forced) {
      //alert(
      this.$root.$emit("isLoading", true);

      forced = this.areDatesDifferents() || forced || false;
      if (!this.pendingRequest && forced) {
        this.pendingRequest = true;
        var url =
          "/api/v2/tables_period/" +
          this.period +
          "/" +
          this.startDate.getTime() +
          "/" +
          this.endDate.getTime() +
          "/" +
          this.currentPage;

        this.$http.get(url, this.getConfigAuth()).then((results) => {
          this.$root.$emit("isLoading", false);
          var result = results.data;
          this.tables = result.results;
          this.totalRecords = result.count;
          this.totalPages = result.totalPages;
          this.rowsPerPage = result.rowsPerPage;
          this.pendingRequest = false;
          this.areDatesDifferents(true);
          this.calculateTotals();

          this.tablesRunning = this.tables.some(
            (table) => table.status === 2 || table.status === 1
          );
          this.areDatesDifferents(true);
          this.countdownToRefresh = this.secondsToRefresh;

          if (this.tablesRunning) {
            this.setTimeoutJobs();
            this.initCountdown();
          }
        });
      } else {
        this.calculateTotals();
      }
    },

    areDatesDifferents: function (setDates) {
      setDates = setDates || false;
      var resStart = this.isStartDateDifferent(),
        resEnd = this.isEndDateDifferent();
      if (setDates) {
        if (resStart) {
          this.prevStartDate = new Date(this.startDate);
        }
        if (resEnd) {
          this.prevEndDate = new Date(this.endDate);
        }
      }
      return resStart || resEnd;
    },

    isStartDateDifferent: function () {
      return (
        this.prevStartDate === null ||
        this.startDate.toLocaleString() !== this.prevStartDate.toLocaleString()
      );
    },

    isEndDateDifferent: function () {
      return (
        this.prevEndDate === null ||
        this.endDate.toLocaleString() !== this.prevEndDate.toLocaleString()
      );
    },

    calculateTotals: function () {
      if (this.tables.length) {
        this.resetTotalsData();
        var that = this;

        this.tables.forEach(function (table) {
          that.totalRows += table.totalRows;
          that.totalRowsOk += table.totalRowsOk;
          that.totalCredits += table.cost;
          //If we don't have finalPrice, used, estimated one
          that.totalCost += table.price;
          that.totalSavings += table.estimatedPrice - table.price;
        });
      }
    },

    resetTotalsData: function () {
      this.totalRows = 0;
      this.totalRowsOk = 0;
      this.totalCost = 0;
      this.totalCredits = 0;
      this.totalSavings = 0;
    },

    tableTotals: function () {
      this.updateTotalsData();
      //this.getSortingValues();
    },

    updateTotalsData: function () {
      this.totalRows = this.getTotalRows();
      this.totalRowsOk = this.getTotalRowsOk();
      this.totalCost = this.getTotalCost();
      this.totalSavings = this.getTotalSavings();
      this.totalCredits = this.getTotalCredits();
    },

    getTotalRows: function () {
      return this.totalRows;
    },

    getTotalRowsOk: function () {
      return this.totalRowsOk;
    },

    getTotalCost: function () {
      return this.totalCost;
    },

    getTotalSavings: function () {
      return this.totalSavings;
    },

    getTotalCredits: function () {
      return this.totalCredits;
    },

    downloadOriginal: function (id) {
      window.location.href = this.getOriginalFileUrl(id);
    },

    downloadOriginalSigned: function (id) {
      this.getOriginalFileSignedUrl(id);
    },

    getOriginalFileSignedUrl: function (tableId) {
      var url =
        this.$apiBase + "/api/v2/table/" + tableId + "/downloadsigned/original";
      this.$http.get(url, this.config).then((results) => {
        if (
          results &&
          results.data &&
          results.data.url &&
          results.data.url.length
        ) {
          window.location.href = results.data.url;
        }
      });
    },

    getOriginalFileUrl: function (tableId) {
      return (
        this.$apiBase +
        "/api/v1/" +
        localStorage.token +
        "/table/download/" +
        tableId +
        "/original"
      );
    },

    downloadResults: function (id) {
      window.location.href = this.getResultsFileUrl(id);
    },

    getResultsFileUrl: function (tableId) {
      return (
        this.$apiBase +
        "/api/v1/" +
        localStorage.token +
        "/table/download/" +
        tableId +
        "/results-final"
      );
    },

    deleteTable: function (table) {
      console.log("table", table);
      if (confirm(this.$t("table-delete-confirm", { name: table.name }))) {
        //var url = "/api/v2/tables/" + table.id;
        //this.$http.delete(url, this.getConfigAuth()).then(() => {
          this.$toast.success(this.$t("table-deleted", { name: table.name }));
        //  this.getTables(true);
        //});
      }
    },

    downloadTable: function (table) {
      console.log("table", table);
      //var url = "/api/v2/tables/" + table.id + "/download";
      //this.$http.get(url, this.getConfigAuth()).then(() => {
        this.$toast.success(this.$t("table-downloaded", { name: table.name }));
      //});
    },

    updatePaginationValues: function () {
      //this.currentPage = this.getCurrentPage() + 1;
      this.totalPages = this.getTotalPages();
    },

    getCurrentPage: function () {
      return this.currentPage;
    },

    getTotalPages: function () {
      return this.totalPages;
    },

    isFirstPage: function () {
      return (
        (this.totalPages > 0 && this.currentPage === 0) ||
        (this.totalPages === 0 && this.currentPage === 0)
      );
    },

    isLastPage: function () {
      return (
        (this.totalPages > 0 && this.currentPage === this.totalPages - 1) ||
        (this.totalPages === 0 && this.currentPage === 0)
      );
    },

    moveNext: function () {
      this.clearCountdown();
      if (this.isLastPage()) {
        this.loading = true;
      }
      if (!this.isLastPage()) {
        this.currentPage++;
        this.getTables(true);
      }
    },

    movePrevious: function () {
      this.clearCountdown();
      if (this.isFirstPage()) {
        this.loading = true;
      }
      if (!this.isFirstPage()) {
        this.currentPage--;
        this.getTables(true);
      }
    },
  },
};
</script>
