<template>
<div class="col-md-3 col-lg-2 no_side_pad collapse_menu mobileViewMenu" v-bind:class="{ collapse_active: isActive }">
  <div class="nav-side-menu">
    <div class="brand"></div>


    <div class="toggle-btn" :data-target="admin ? '#admin-menu-content' : '#menu-content'" data-toggle="collapse" :key="admin ? 'admin' : 'user'">
      <div class="bar1"></div>
      <div class="bar2"></div>
      <div class="bar3"></div>
    </div>

    <div class="menu-list">
      <ul id="admin-menu-content" class="menu-content collapse out" v-if="admin">
        <li v-on:click="logoutAdmin">
          <a v-bind:href="'#/dashboard'" :title="$t('menu-back')">
            <font-awesome-icon icon="sign-out-alt" size="lg" /><span >{{ $t('menu-back') }}</span>
          </a>
        </li>
      </ul>

      <ul id="admin-menu-content" class="menu-content collapse out" v-if="admin" >
        <li v-bind:class="{ active: admin_dashboard }" v-on:click="redirect('/admin/dashboard')" v-if="loginChecker()" id="admin_dashboard">
          <a v-bind:href="'#/admin/dashboard'" :title="$t('menu-statistics')">
            <font-awesome-icon icon="home" size="lg" v-bind:class="{ 'blue-link': admin_dashboard }" /><span v-bind:class="{ 'blue-link': admin_dashboard }">{{ $t('menu-panel' ) }}</span>
          </a>
        </li>

        <li data-toggle="collapse" class="collapsed " v-bind:class="{ active: admin_mydata }" v-on:click="redirect('/admin/mydata/bulks')" v-if="loginChecker()">
          <a href="#/admin/mydata/bulks" :title="$t('menu-alldata')">
            <font-awesome-icon icon="database" size="lg" v-bind:class="{ 'blue-link': admin_mydata }" /><span v-bind:class="{ 'blue-link': admin_mydata }">{{ $t('menu-alldata' ) }}</span>
          </a>
        </li>

        <li v-bind:class="{ active: admin_sales }" v-on:click="redirect('/admin/sales')" v-if="loginChecker()" id="admin_sales">
          <a v-bind:href="'#/admin/sales'" :title="$t('menu-sales')">
            <font-awesome-icon icon="credit-card" size="lg" v-bind:class="{ 'blue-link': admin_sales }" /><span v-bind:class="{ 'blue-link': admin_sales }">{{ $t('menu-sales' ) }}</span>
          </a>
        </li>

        <li v-bind:class="{ active: admin_users }" v-on:click="redirect('/admin/users')" v-if="loginChecker()" id="admin_users">
          <a v-bind:href="'#/admin/users'" :title="$t('menu-users')">
            <font-awesome-icon icon="users" size="lg" v-bind:class="{ 'blue-link': admin_users }" /><span v-bind:class="{ 'blue-link': admin_users }">{{ $t('menu-users' ) }}</span>
          </a>
        </li>
        
        <li v-bind:class="{ active: admin_tools }" v-on:click="redirect('/admin/tools')" v-if="loginChecker()" id="admin_tools">
          <a v-bind:href="'#/admin/tools'" :title="$t('menu-tools')">
            <font-awesome-icon icon="lightbulb" size="lg" v-bind:class="{ 'blue-link': admin_tools }" /><span v-bind:class="{ 'blue-link': admin_tools }">{{ $t('menu-tools' ) }}</span>
          </a>
        </li>

        <li v-bind:class="{ active: admin_changes }" v-on:click="redirect('/admin/changes')" v-if="loginChecker()" id="admin_changes">
          <a v-bind:href="'#/admin/changes'" :title="$t('menu-changes')">
            <font-awesome-icon icon="exchange-alt" size="lg" v-bind:class="{ 'blue-link': admin_changes }" /><span v-bind:class="{ 'blue-link': admin_changes }">{{ $t('menu-changes' ) }}</span>
          </a>
        </li>

        <li v-bind:class="{ active: admin_system }" v-on:click="redirect('/admin/system')" v-if="loginChecker()" id="admin_system">
          <a v-bind:href="'#/admin/system'" :title="$t('menu-system')">
            <font-awesome-icon icon="cog" size="lg" v-bind:class="{ 'blue-link': admin_system }" /><span v-bind:class="{ 'blue-link': admin_system }">{{ $t('menu-system' ) }}</span>
          </a>
        </li>
      </ul>


      <ul id="menu-content" class="menu-content collapse out" v-if="!admin">
        <li v-bind:class="{ active: welcome }" id="welcome">
          <a v-bind:href="'#/welcome'" :title="$t('menu-welcome')">
            <font-awesome-icon icon="bullseye" size="lg" v-bind:class="{ 'blue-link': welcome }" /><span v-bind:class="{ 'blue-link': welcome }">
              {{ $t('menu-welcome') }}
              {{ profile && profile.length > 0 ? ' (' + $t('search-profile-' + profile) + ')' : '' }}
            </span>
          </a>
        </li>

        <li data-toggle="collapse" data-target="#service" class="collapsed " v-bind:class="{ active: wizard }" v-on:click="redirect('/wizard/bulk')" v-if="loginChecker()">
          <a v-bind:href="'#/wizard/file'" :title="$t('menu-wizard')">
            <font-awesome-icon icon="magic" size="lg" v-bind:class="{ 'blue-link': wizard }" /><span v-bind:class="{ 'blue-link': wizard }">{{ $t('menu-wizard' ) }}</span>
          </a>
        </li>

        <!-- new mydata menu -->
        <li data-toggle="collapse" data-target="#mydata" class="collapsed " v-bind:class="{ active: mydata }" v-on:click="redirect('/mydata/bulks')" v-if="loginChecker()">
          <a href="#/mydata/bulks" :title="$t('menu-mydata')">
            <font-awesome-icon icon="database" size="lg" v-bind:class="{ 'blue-link': mydata }" /><span v-bind:class="{ 'blue-link': mydata }">{{ $t('menu-mydata' ) }}</span>
          </a>

          <!-- <ul class="submenu ml-3" v-if="isAdmin">
            <li v-bind:class="{ active: schedule }" v-on:click="redirect('/mydata/schedules')" v-if="loginChecker()" id="schedules">
              <a v-bind:href="'#/mydata/schedules'" :title="$t('menu-schedule')">
                <font-awesome-icon icon="clock" size="lg" v-bind:class="{ 'blue-link': schedule }" /><span v-bind:class="{ 'blue-link': schedule }">{{ $t('menu-schedule' ) }}</span>
              </a>
            </li>

            <li v-bind:class="{ active: bulks }" v-on:click="redirect('/mydata/bulks')" v-if="loginChecker()" id="bulks">
              <a v-bind:href="'#/mydata/bulks'" :title="$t('menu-bulks')">
                <font-awesome-icon icon="tasks" size="lg" v-bind:class="{ 'blue-link': bulks }" /><span v-bind:class="{ 'blue-link': bulks }">{{ $t('menu-bulks' ) }}</span>
              </a>
            </li>

            <li v-bind:class="{ active: lists }" v-on:click="redirect('/mydata/lists')" v-if="loginChecker()" id="lists">
              <a v-bind:href="'#/mydata/lists'" :title="$t('menu-lists')">
                <font-awesome-icon icon="users" size="lg" v-bind:class="{ 'blue-link': lists }" /><span v-bind:class="{ 'blue-link': lists }">{{ $t('menu-lists' ) }}</span>
              </a>
            </li>

            <li v-bind:class="{ active: tables }" v-on:click="redirect('/mydata/tables')" v-if="loginChecker()" id="tables">
              <a v-bind:href="'#/mydata/tables'" :title="$t('menu-tables')">
                <font-awesome-icon icon="table" size="lg" v-bind:class="{ 'blue-link': tables }" /><span v-bind:class="{ 'blue-link': tables }">{{ $t('menu-tables' ) }}</span>
              </a>
            </li>
            
          </ul> -->
        </li>

        <li v-bind:class="{ active: tools }" v-on:click="redirect('/tools')" v-if="loginChecker()">
          <a v-bind:href="'#/tools'" :title="$t('menu-tools')">
            <font-awesome-icon icon="lightbulb" size="lg" v-bind:class="{ 'blue-link': tools }" /><span v-bind:class="{ 'blue-link': tools }">{{ $t('menu-tools' ) }}</span>
          </a>
        </li>
        <li>
          <hr />
        </li>
        
        <li v-bind:class="{ active: welcome }" v-bind:href="'#/login'" v-if="!loginChecker()">
          <a v-bind:href="'#/login'">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M11.5 3.79297L16 8.29297V6.00008H17V9.29297L19.8536 12.1465L19.1465 12.8536L11.5 5.20718L3.85359 12.8536L3.14648 12.1465L11.5 3.79297ZM5.00004 13.0001H6.00004V20.0001H10V16.0001H14V20.0001H17V13.0001H18V21.0001H13V17.0001H11V21.0001H5.00004V13.0001Z" />
            </svg><span>{{ $t('menu-login' ) }}</span>
          </a>
        </li>

        <li v-bind:class="{ active: dashboard }" v-bind:href="'#/login'" v-if="!loginChecker()">
          <a v-bind:href="'#/login'">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M11.5 3.79297L16 8.29297V6.00008H17V9.29297L19.8536 12.1465L19.1465 12.8536L11.5 5.20718L3.85359 12.8536L3.14648 12.1465L11.5 3.79297ZM5.00004 13.0001H6.00004V20.0001H10V16.0001H14V20.0001H17V13.0001H18V21.0001H13V17.0001H11V21.0001H5.00004V13.0001Z" />
            </svg><span>{{ $t('menu-login' ) }}</span>
          </a>
        </li>

        <li v-bind:class="{ active: dashboard }" v-on:click="redirect('/dashboard')" v-if="loginChecker()" id="dashboard">
          <a v-bind:href="'#/dashboard'" :title="$t('menu-panel')">
            <font-awesome-icon icon="home" size="lg" v-bind:class="{ 'blue-link': dashboard }" /><span v-bind:class="{ 'blue-link': dashboard }">{{ $t('menu-panel' ) }}</span>
          </a>
        </li>

        <li data-toggle="collapse" data-target="#products" class="collapsed " v-bind:class="{ active: purchase }" v-on:click="redirect('/purchase')" v-if="loginChecker()">
          <a v-bind:href="'#/purchase'" :title="$t('menu-purchase')">
            <font-awesome-icon icon="shopping-cart" size="lg" v-bind:class="{ 'blue-link': purchase }" /><span v-bind:class="{ 'blue-link': purchase }">{{ $t('menu-purchase' ) }}</span>
          </a>
        </li>

        <li v-bind:class="{ active: settings }" v-on:click="redirect('/settings')" v-if="loginChecker()">
          <a v-bind:href="'#/settings'" :title="$t('menu-setting')">
            <font-awesome-icon icon="cog" size="lg" v-bind:class="{ 'blue-link': settings }" /><span v-bind:class="{ 'blue-link': settings }">{{ $t('menu-setting' ) }}</span>
          </a>
        </li>

        <li v-bind:class="{ active: changes }" v-on:click="redirect('/changes')" v-if="loginChecker()">
          <a v-bind:href="'#/changes'" :title="$t('menu-changes')">
            <font-awesome-icon icon="exchange-alt" size="lg" v-bind:class="{ 'blue-link': changes }" /><span v-bind:class="{ 'blue-link': changes }">{{ $t('menu-changes' ) }}</span>
          </a>
        </li>

        <li v-bind:class="{ active: contact }" v-on:click="redirect('/contact')" v-if="loginChecker()">
          <a v-bind:href="'#/contact'" :title="$t('menu-help')">
            <font-awesome-icon icon="comments" size="lg" v-bind:class="{ 'blue-link': contact }" /><span v-bind:class="{ 'blue-link': contact }">{{ $t('menu-help' ) }}</span>
          </a>
        </li>

        
        <li id="admin-signin" v-if="loginChecker() && isAdmin">
          <a v-bind:href="'#/admin/dashboard'" :title="$t('menu-admin')" v-on:click="loginAdmin">
            <font-awesome-icon icon="lock" size="lg"/><span>{{ $t('menu-admin' ) }}</span>
          </a>
        </li>

        <li  v-on:click="logOut()" v-if="loginChecker()">
          <a v-bind:href="'#/logout'" :title="$t('menu-logout')">
            <font-awesome-icon icon="sign-out-alt" size="lg"/><span>{{ $t('menu-logout' ) }}</span>
          </a>
        </li>

       
      </ul>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'Sidebar',
  data() {
    return {
      isActive: false,
      isActiveIcon: '<',
      isAdmin: false,

      profile: '',

      // Admin Menu
      admin: false,
      admin_dashboard: false,
      admin_users: false,
      admin_sales: false,
      admin_mydata: false,
      admin_tools: false,
      admin_changes: false,
      admin_system: false,

      // Sections
      welcome: false,
      dashboard: false,
      mydata: false,
      purchase: false,
      wizard: false,
      tools: false,
      contact: false,
      settings: false,
      changes: false,

      // Sub Sections
      marketing: false,
      sales: false,
      marketplace: false,
      technical: false,

      // Mydata Sub Sections
      schedule: false,
      lists: false,
      tables: false,
      bulks: false,
    };
  },
  mounted() {
    this.detectSection();
    this.$root.$on('isActived', (result) => {
      this.isActive = result;
      localStorage.isActive = result;
      if (this.isActive)
        this.isActiveIcon = '>'
      else
        this.isActiveIcon = '<'
    })

    this.isAdmin = localStorage.role == 'admin';
    this.initProfile();
  },
  watch: {
    'localStorage.toolsProfile'() {
      this.initProfile();
    }
  },
  methods: {
    getConfigAuth() {
      return {
        headers: {
          Authorization:
            "Basic " +
            btoa(localStorage.configEmail + ":" + localStorage.token),
          "Content-Type": "application/json",
        },
      };
    },

    initProfile: function() {
      this.profile = localStorage.toolsProfile || "";
    },

    loginAdmin: function() {
      this.admin = true;
      this.detectSection();
      this.admin_dashboard = true;
      this.$router.push("/admin/dashboard");
    },

    logoutAdmin: function() {
      this.admin = false;
      this.dashboard = true;
      this.$router.push("/dashboard");
    },

    detectSection: function() {
      if (this.$route.path.includes("/admin")) {
        this.admin = true;
      }

      if (this.admin) {
        if (this.$route.path.includes("dashboard")) {
          this.admin_dashboard = true;
        } else if (this.$route.path.includes("mydata")) {
          this.admin_mydata = true;
        } else if (this.$route.path.includes("users")) {
          this.admin_users = true;
        } else if (this.$route.path.includes("sales")) {
          this.admin_sales = true;
        } else if (this.$route.path.includes("tools")) {
          this.admin_tools = true;
        } else if (this.$route.path.includes("changes")) {
          this.admin_changes = true;
        } else if (this.$route.path.includes("system")) {
          this.admin_system = true;
        }
      } else {
        if (this.$route.path.includes("dashboard")) {
          this.dashboard = true;
        } else if (this.$route.path.includes("mydata") || this.$route.path.includes("bulks")) {
          this.mydata = true;
        } else if (this.$route.path.includes("welcome")) {
          this.welcome = true;
        } else if (this.$route.path.includes("wizard")) {
          this.wizard = true;
        } else if (this.$route.path.includes("tools")) {
          this.tools = true;
        } else if (this.$route.path.includes("contact")) {
          this.contact = true;
        } else if (this.$route.path.includes("settings")) {
          this.settings = true;
        } else if (this.$route.path.includes("changes")) {
          this.changes = true;
        }
      }
      
    },
    
    getUserDetails: function () {
      var url = "/api/v2/profile";
      this.$http.get(url, this.getConfigAuth()).then((results) => {
        this.user = results.data;
        this.isAdmin = this.user.role == 'admin';
        //this.admin = this.isAdmin;
      });
    },
    
    redirect(route) {
      this.$router.push(route);
    },

    loginChecker() {
      return localStorage.token && localStorage.token != this.$defaultKey ? true : false;
    },

    logOut: function() {
      localStorage.token = '';
      this.$router.push("/login");
      location.reload();
    },

  }
}
</script>
