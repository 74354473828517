<template>
  <div>
    <div class="row no_side_margin">
      <div class="col-lg-12 no_side_pad">
        <div class="row" v-if="subsection === 'settings'">
          <div class="col-lg-12">
            <Settings v-on:change-subsection="setSubsection" />
          </div>
        </div>
        <div v-else-if="subsection === 'view'">
            <div class="row">
              <div class="col-6 text-left">
              <div class="pb-4">
                <a v-on:click="back()">
                {{ $t("back") }}
                </a>
              </div>
              </div>
            </div>

            <div class="row pb-4">
              <div class="col-md-9">
                <h2>{{ $t('table') }} {{ tableId }} ({{ items.length }})</h2>
              </div>
              <div class="col-3 text-right">
                <button
                  type="button"
                  class="btn btn-secondary"
                  @click="setSubsection('settings')"
                >
                  <font-awesome-icon icon="cog" size="xs" />
                  {{ $t("settings") }}
                </button>
              </div>
            </div>

            <!-- <div class="row pt-4 pb-2">
              <div
                class="col-lg-12 col-md-12"
                v-if="items.length > 0"
                v-html="
                  $t('list-pagination', {
                    startItems: items.length,
                    finishItems: items.length,
                  })
                "
              ></div>
          </div> -->
          <div
            class="col-lg-12 no_side_pad request"
            v-if="this.items && this.items.length"
          >
            <div class="page-nav">
              <div class="row no_side_margin">
                <div class="col-lg-2 col-sm-4 col-4 text-right">
                  <div>
                    <a
                      :class="{ disabled: isFirstPage(), '': !isFirstPage() }"
                      v-on:click="movePrevious()"
                    >
                      <span class="nav-icon">←</span> {{ $t("previous") }}
                    </a>
                  </div>
                </div>

                <div class="col-lg-8 col-sm-4 col-4 no_side_pad text-center">
                  {{ $t("page") }} {{ this.currentPage + 1 }} /
                  {{ this.totalPages }}
                </div>

                <div class="col-lg-2 col-sm-4 col-4 no_side_pad">
                  <div>
                    <a
                      :class="{ disabled: isLastPage(), '': !isLastPage() }"
                      v-on:click="moveNext()"
                    >
                      {{ $t("next") }} <span class="nav-icon">→</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row smry-status no_side_margin" style="overflow-x: auto">
            <table
              class="table table-striped list-table"
              style="margin-top: 0px"
               v-if="tableId < 6"
            >
              <thead>
                <tr>
                  <th class="t-head">{{ $t("date") }}</th>
                  <th class="t-head">{{ $t("name") }}</th>
                  <th class="t-head">{{ $t("email") }}</th>
                  <th class="t-head text-right">{{ $t("cost") }}</th>
                  <th class="t-head text-right">{{ $t("actions") }}</th>
                </tr>
              </thead>

              <tbody v-if="!this.items.length">
                <tr>
                  <td colspan="5" class="text-center table-col">
                    {{ $t("no-results") }}
                  </td>
                </tr>
              </tbody>

              <tbody v-if="this.items.length" class="text-sm">
                <tr v-for="(item, index) in this.items" v-bind:key="index">
                  <td class="text-col">{{ item.date }}</td>
                  <td class="text-col">{{ item.name }}</td>
                  <td class="text-col">{{ item.email }}</td>
                  <td class="text-col text-right">
                    {{ item.cost }}{{ currency }}
                  </td>
                  <td class="text-col text-right">
                    <div class="icon">
                      <a
                        :href="'#/mydata/tables/' + tableId"
                        :title="$t('delete-item')"
                        v-on:click="deleteElement(item.id)"
                      >
                        <font-awesome-icon icon="trash" />
                      </a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <table
              class="table table-striped list-table"
              style="margin-top: 0px"
               v-else
            >
              <thead>
                <tr>
                  <th class="t-head">{{ $t("date") }}</th>
                  <th class="t-head">{{ $t("company") }}</th>
                  <th class="t-head">{{ $t("address") }}</th>
                  <th class="t-head">{{ $t("zipcode") }}</th>
                  <th class="t-head">{{ $t("city") }}</th>
                  <th class="t-head">{{ $t("state") }}</th>
                  <th class="t-head">{{ $t("country") }}</th>
                  <th class="t-head">{{ $t("phone") }}</th>
                  <th class="t-head">{{ $t("email") }}</th>
                  <th class="t-head">{{ $t("updates") }}</th>
                  <th class="t-head text-right">{{ $t("cost") }}</th>
                  <th class="t-head">{{ $t("updated") }}</th>
                  <th class="t-head text-right">{{ $t("actions") }}</th>
                </tr>
              </thead>

              <tbody v-if="!this.itemsPlaces.length">
                <tr>
                  <td colspan="12" class="text-center table-col">
                    {{ $t("no-results") }}
                  </td>
                </tr>
              </tbody>

              <tbody v-if="this.itemsPlaces.length" class="text-sm">
                <tr v-for="(item, index) in this.itemsPlaces" v-bind:key="index">
                  <td class="text-col">{{ item.date }}</td>
                  <td class="text-col">{{ item.company }}</td>
                  <td class="text-col">{{ item.streetAddress }}</td>
                  <td class="text-col">{{ item.zipcode }}</td>
                  <td class="text-col">{{ item.city }}</td>
                  <td class="text-col">{{ item.state }}</td>
                  <td class="text-col">{{ item.country }}</td>
                  <td class="text-col">{{ item.phone }}</td>
                  <td class="text-col">{{ item.email }}</td>
                  <td class="text-col">{{ item.updates }}</td>
                  <td class="text-col text-right">{{ item.cost }}{{ currency }}</td>
                  <td class="text-col">{{ item.lastUpdate }}</td>
                  <td class="text-col text-right">
                    <div class="icon">
                      <a
                        :href="'#/mydata/tables/' + tableId"
                        :title="$t('delete-item')"
                        v-on:click="deleteElement(item.id)"
                      >
                        <font-awesome-icon icon="trash" />
                      </a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Settings from "./Settings.vue";

export default {
  name: "list",
  data() {
    return {
      user: null,
      tableId: null,
      itemsHeaders: [
        { text: "Date", value: "date" },
        { text: "Update", value: "lastUpdate" },
        { text: "Name", value: "name" },
        { text: "Email", value: "email" },
        { text: "Cost", value: "cost" },
      ],
      items: [
        {
          date: "2023-10-01",
          name: "John Doe",
          email: "john.doe@example.com",
          cost: (50 / 100).toFixed(2),
          lastUpdate: "2023-10-11",
          id: 1,
        },
        {
          date: "2023-10-02",
          name: "Jane Smith",
          email: "jane.smith@example.com",
          cost: (100 / 100).toFixed(2),
          lastUpdate: "2023-10-12",
          id: 2,
        },
        {
          date: "2023-10-03",
          name: "Alice Johnson",
          email: "alice.johnson@example.com",
          cost: (75 / 100).toFixed(2),
          lastUpdate: "2023-10-13",
          id: 3,
        },
        {
          date: "2023-10-04",
          name: "Bob Brown",
          email: "bob.brown@example.com",
          cost: (60 / 100).toFixed(2),
          lastUpdate: "2023-10-14",
          id: 4,
        },
        {
          date: "2023-10-05",
          name: "Charlie Davis",
          email: "charlie.davis@example.com",
          cost: (90 / 100).toFixed(2),
          lastUpdate: "2023-10-15",
          id: 5,
        },
        {
          date: "2023-10-06",
          name: "Diana Evans",
          email: "diana.evans@example.com",
          cost: (80 / 100).toFixed(2),
          lastUpdate: "2023-10-16",
          id: 6,
        },
        {
          date: "2023-10-07",
          name: "Frank Green",
          email: "frank.green@example.com",
          cost: (70 / 100).toFixed(2),
          lastUpdate: "2023-10-17",
          id: 7,
        },
        {
          date: "2023-10-08",
          name: "Grace Harris",
          email: "grace.harris@example.com",
          cost: (65 / 100).toFixed(2),
          lastUpdate: "2023-10-18",
          id: 8,
        },
        {
          date: "2023-10-09",
          name: "Henry Lee",
          email: "henry.lee@example.com",
          cost: (85 / 100).toFixed(2),
          lastUpdate: "2023-10-19",
          id: 9,
        },
        {
          date: "2023-10-10",
          name: "Ivy Martinez",
          email: "ivy.martinez@example.com",
          cost: (55 / 100).toFixed(2),
          lastUpdate: "2023-10-20",
          id: 10,
        },
      ],
      itemsPlacesHeaders: [
        { text: "Date", value: "date" },
        { text: "Update", value: "lastUpdate" },
        { text: "Company", value: "company" },
        { text: "Email", value: "email" },
        { text: "Phone", value: "phone" },
        { text: "Address", value: "address" },
        { text: "City", value: "city" },
        { text: "Zipcode", value: "zipcode" },
        { text: "State", value: "state" },
        { text: "Country", value: "country" },
        { text: "Website", value: "website" },
        { text: "Cost", value: "cost" },
      ],
      itemsPlaces: [
        {
          date: "2023-10-01",
          company: "Business 1",
          email: "info@domain.com",
          phone: "+34 123 456 789",
          country: "Spain",
          lastUpdate: "2023-10-11",
          streetAddress: "123 Main St",
          zipcode: "28001",
          state: "Madrid",
          city: "Madrid",
          website: "https://www.business1.com",
          cost: (Math.random()).toFixed(2),
          updates: Math.floor(Math.random() * 10) + 1,
          id: 1,
        },
        {
          date: "2023-10-02",
          company: "Business 2",
          email: "info@domain2.com",
          phone: "+34 123 456 788",
          country: "Spain",
          lastUpdate: "2023-10-12",
          streetAddress: "456 Elm St",
          zipcode: "28002",
          state: "Madrid",
          city: "Madrid",
          website: "https://www.business2.com",
          cost: (Math.random()).toFixed(2),
          updates: Math.floor(Math.random() * 10) + 1,
          id: 2,
        },
        {
          date: "2023-10-03",
          company: "Business 3",
          email: "info@domain3.com",
          phone: "+34 123 456 787",
          country: "Spain",
          lastUpdate: "2023-10-13",
          streetAddress: "789 Oak St",
          zipcode: "28003",
          state: "Madrid",
          city: "Madrid",
          website: "https://www.business3.com",
          cost: (Math.random()).toFixed(2),
          updates: Math.floor(Math.random() * 10) + 1,
          id: 3,
        },
        {
          date: "2023-10-04",
          company: "Business 4",
          email: "info@domain4.com",
          phone: "+34 123 456 786",
          country: "Spain",
          lastUpdate: "2023-10-14",
          streetAddress: "101 Pine St",
          zipcode: "28004",
          state: "Madrid",
          city: "Madrid",
          website: "https://www.business4.com",
          cost: (Math.random()).toFixed(2),
          updates: Math.floor(Math.random() * 10) + 1,
          id: 4,
        },
        {
          date: "2023-10-05",
          company: "Business 5",
          email: "info@domain5.com",
          phone: "+34 123 456 785",
          country: "Spain",
          lastUpdate: "2023-10-15",
          streetAddress: "202 Maple St",
          zipcode: "28005",
          state: "Madrid",
          city: "Madrid",
          website: "https://www.business5.com",
          cost: (Math.random()).toFixed(2),
          updates: Math.floor(Math.random() * 10) + 1,
          id: 5,
        },
        {
          date: "2023-10-06",
          company: "Business 6",
          email: "info@domain6.com",
          phone: "+34 123 456 784",
          country: "Spain",
          lastUpdate: "2023-10-16",
          streetAddress: "303 Birch St",
          zipcode: "28006",
          state: "Madrid",
          city: "Madrid",
          website: "https://www.business6.com",
          cost: (Math.random()).toFixed(2),
          updates: Math.floor(Math.random() * 10) + 1,
          id: 6,
        },
        {
          date: "2023-10-07",
          company: "Business 7",
          email: "info@domain7.com",
          phone: "+34 123 456 783",
          country: "Spain",
          lastUpdate: "2023-10-17",
          streetAddress: "404 Cedar St",
          zipcode: "28007",
          state: "Madrid",
          city: "Madrid",
          website: "https://www.business7.com",
          cost: (Math.random()).toFixed(2),
          updates: Math.floor(Math.random() * 10) + 1,
          id: 7,
        },
        {
          date: "2023-10-08",
          company: "Business 8",
          email: "info@domain8.com",
          phone: "+34 123 456 782",
          country: "Spain",
          lastUpdate: "2023-10-18",
          streetAddress: "505 Walnut St",
          zipcode: "28008",
          state: "Madrid",
          city: "Madrid",
          website: "https://www.business8.com",
          cost: (Math.random()).toFixed(2),
          updates: Math.floor(Math.random() * 10) + 1,
          id: 8,
        },
        {
          date: "2023-10-09",
          company: "Business 9",
          email: "info@domain9.com",
          phone: "+34 123 456 781",
          country: "Spain",
          lastUpdate: "2023-10-19",
          streetAddress: "606 Chestnut St",
          zipcode: "28009",
          state: "Madrid",
          city: "Madrid",
          website: "https://www.business9.com",
          cost: (Math.random()).toFixed(2),
          updates: Math.floor(Math.random() * 10) + 1,
          id: 9,
        },
        {
          date: "2023-10-10",
          company: "Business 10",
          email: "info@domain10.com",
          phone: "+34 123 456 780",
          country: "Spain",
          lastUpdate: "2023-10-20",
          streetAddress: "707 Ash St",
          zipcode: "28010",
          state: "Madrid",
          city: "Madrid",
          website: "https://www.business10.com",
          cost: (Math.random()).toFixed(2),
          updates: Math.floor(Math.random() * 10) + 1,
          id: 10,
        },
      ],
      subsection: "view",
      currentPage: 0,
      totalPages: 1,
      totalRows: 0,
      currency: "€",

      processing: [],
      downloaded: false,
      forwarded: false,
      new_forward: false,
      forward: null,
      forwardOriginal: null,
      hasToBeSent: false,

      shownPeopleFields: false,
      shownWebFields: false,
    };
  },
  mounted() {
    //this.getUserDetails();
    this.detectTableId();
  },
  components: {
    Settings,
  },
  computed: {},

  methods: {
    getConfigAuth: function () {
      return {
        headers: {
          Authorization:
            "Basic " +
            btoa(localStorage.configEmail + ":" + localStorage.token),
          "Content-Type": "application/json",
        },
      };
    },

    detectTableId: function () {
      if (this.$route.params.name !== undefined) {
        this.tableId = parseInt(this.$route.params.name, 10);
        //this.getList(this.$route.params.name, true);
      }
    },

    setSubsection: function (subsection) {
      this.subsection = subsection;
    },

    back: function () {
      this.$emit("change-section", "list");
      this.$router.push("/mydata/tables");
    },

    showPeopleFields() {
      this.shownPeopleFields = !this.shownPeopleFields;
    },
    showWebFields() {
      this.shownWebFields = !this.shownWebFields;
    },

    isFirstPage: function () {
      return this.currentPage == 0;
    },

    movePrevious: function () {
      if (!this.isFirstPage()) {
        this.movePreviousProcess();
        this.updateListData(true);
      }
    },

    movePreviousProcess: function () {
      this.currentPage--;
    },

    isLastPage: function () {
      return this.currentPage == this.totalPages - 1;
    },

    moveNext: function () {
      if (!this.isLastPage()) {
        this.moveNextProcess();
        this.updateListData(true);
      }
    },

    moveNextProcess: function () {
      this.currentPage++;
    },

    getUserDetails: function () {
      var url = "/api/v2/profile";
      this.$http.get(url, this.getConfigAuth()).then((results) => {
        this.user = results.data;
      });
    },

    getEmail: function () {
      return this.user
        ? this.user.email
        : localStorage.configEmail
        ? localStorage.configEmail
        : "test@uproc.io";
    },

    getBalance: function () {
      return this.user ? this.getCredits() * this.getCostPerCredit() : 0;
    },

    getCostPerCredit: function () {
      return this.processing.costPerCredit;
    },

    getProcessingValues: function () {
      var url = "/api/v2/processing";
      this.$http.get(url, this.getConfigAuth()).then((results) => {
        this.processing = results.data;
      });
    },

    showNameInput: function () {
      this.editMode = true;
      this.$nextTick(() => {
        this.$refs.list_name.focus();
        this.$refs.list_name.select();
      });
    },

    //FROM HERE CURRENT LIST AREA FUNCTIONS
    updateListData: function (forced) {
      if (this.$route.params.name !== undefined) {
        this.getList(this.$route.params.name, forced);
      }
    },

    getList: function (name, forced) {
      if (!this.pendingRequest && forced) {
        this.$root.$emit("isLoading", true);
        this.pendingRequest = true;
        this.loading = true;
        var url = "/api/v2/list/" + name + "?page=" + this.list.currentPage;
        this.$http.get(url, this.getConfigAuth()).then((results) => {
          //console.log(this.getCurrentReport(results.data));
          this.list = this.getCurrentReport(results.data); //console.log(this.list);
          this.pendingRequest = false;
          this.loading = false;
          this.startItems = this.list.pageSize * this.list.currentPage;
          this.finishItems = this.startItems + this.list.elements.length;
          this.$root.$emit("isLoading", false);
        });
      }
    },

    getCurrentReport: function (l) {
      if (l === null || l === undefined) {
        return [];
      }
      var graphs = [];
      var self = this;
      l.elements.forEach(function (e) {
        graphs = self.updateGraphs(graphs, e, "area");
        graphs = self.updateGraphs(graphs, e, "seniority");
        graphs = self.updateGraphs(graphs, e, "country");
        graphs = self.updateGraphs(graphs, e, "gender");
        graphs = self.updateGraphs(graphs, e, "email");
        graphs = self.updateGraphs(graphs, e, "confidence");
        graphs = self.updateGraphs(graphs, e, "phone");
      });
      l.graphs = graphs;

      return l;
    },

    updateGraphs: function (graphs, element, name) {
      var currentGraph = graphs.filter(function (i) {
        return i.key == name;
      });
      var newGraphs = graphs.filter(function (i) {
        return i.key != name;
      });
      var currentGraphItem = null;
      if (!currentGraph.length) {
        currentGraphItem = {
          key: name,
          data: [],
        };
      } else {
        currentGraphItem = currentGraph[0];
      }

      var currentValue =
        element && element[name] && element[name].length
          ? element[name].toLowerCase()
          : "unknown";

      if (name === "email") {
        currentValue = currentValue === "unknown" ? "false" : "true";
      }

      if (name === "phone") {
        currentValue = currentValue === "unknown" ? "false" : "true";
      }

      var currentData = currentGraphItem.data.filter(function (i) {
        return i.key == currentValue;
      });
      var oldData = currentGraphItem.data.filter(function (i) {
        return i.key !== currentValue;
      });

      var item = {};
      if (!currentData.length) {
        item = {
          key: currentValue,
          value: 0,
        };
      } else {
        item = currentData[0];
      }

      item.value++;
      oldData.push(item);

      currentGraphItem.data = oldData;
      newGraphs.push(currentGraphItem);

      return newGraphs;
    },

    isZapierWebhook: function (forward) {
      return forward.indexOf("zapier.com") !== -1;
    },

    hasToBeSaved: function () {
      if (this.forward !== null && this.forwardOriginal !== null) {
        this.hasToBeSent =
          JSON.stringify(this.forward) !== JSON.stringify(this.forwardOriginal);
      } else {
        this.hasToBeSent = false;
      }
    },

    addForward: function () {
      this.hasToBeSent = true;
      this.new_forward = true;
      this.forward = {
        enabled: true,
        to_webhook: "https://beeceptor.com/",
        filters: {
          area: "",
          seniority: "",
          email: "",
          phone: "",
        },
      };
      this.forwardOriginal = {};
    },

    cancelForward: function () {
      this.hasToBeSent = false;
      this.new_forward = false;
    },

    deleteForward: function (id) {
      var new_forwards = [];
      this.list.forwards.filter(function (f) {
        if (f._id !== id) {
          new_forwards.push(f);
        }
      });

      this.list.forwards = new_forwards;
      this.saveList();
    },

    saveForward: function () {
      var new_forwards = [];
      var aux_forward = JSON.parse(JSON.stringify(this.forward));
      aux_forward.filters = [];

      var that = this;
      //Save filters
      Object.keys(this.forward.filters).forEach(function (key) {
        aux_forward.filters.push({
          name: key,
          value: that.forward.filters[key],
        });
      });

      this.list.forwards.filter(function (f) {
        if (f._id !== aux_forward._id) {
          new_forwards.push(f);
        } else {
          new_forwards.push(aux_forward);
        }
      });

      if (!aux_forward._id) {
        new_forwards.push(aux_forward);
      }

      this.list.forwards = new_forwards;
      this.loading = true;
      this.saveList();
    },

    editForward: function (forward) {
      var str = JSON.stringify(forward);
      this.forward = JSON.parse(str);
      this.forwardOriginal = JSON.parse(str);
      this.new_forward = true;
      this.hasToBeSent = false;
    },

    saveList: function () {
      this.loading = true;
      this.list.name = this.list.name.replace(/\//g, "").trim();

      var url = "/api/v2/list/" + this.list.id;
      this.$http.post(url, this.list, this.getConfigAuth()).then((results) => {
        this.list.forwards = results.data.forwards;
        this.forward = null;
        this.new_forward = false;
        this.loading = false;
      });
    },

    executeGenericAction: function (action) {
      this.downloaded = false;
      this.forwarded = false;
      var filters = this.search;
      filters.confidence =
        filters.email.indexOf("-") !== -1 ? filters.email.split("-")[1] : "";
      filters.email =
        filters.email.indexOf("-") !== -1 ? filters.email.split("-")[0] : "";
      this.loading = true;

      var filtersStr = Object.keys(filters)
        .map(function (key) {
          return key + "=" + filters[key];
        })
        .join("&");
      var url = "/api/v2/list/" + this.$route.params.name + "/" + action;
      if (this.list.type === "people") {
        url += "?" + filtersStr;
      }

      this.$http.get(url, this.getConfigAuth()).then(() => {
        //var data = results.data;
        this.loading = false;
        if (action === "download") {
          this.downloaded = true;
        } else {
          this.forwarded = true;
        }
      });
    },

    forwardAction: function () {
      this.executeGenericAction("forward");
    },

    downloadAction: function () {
      this.executeGenericAction("download");
    },

    deleteElementProcess: function (element_id) {
      console.log(element_id);
      //var url = "/api/v2/tables/" + this.tableId + "/" + element_id + "/delete";
      //console.log(url);
      //this.$http.get(url, this.getConfigAuth()).then(() => {
      //  this.getTable(this.$route.params.name, true);
      this.$toast.success(this.$t("table-deleted-entry-success"));
      //});
    },

    deleteElement: function (element_id) {
      //var that = this;
      this.deleteElementProcess(element_id);
      /*
      that.$modal.show('dialog', {
        title: that.$t('table-element-delete-title'),
        text: that.$t('table-element-delete-description'),
        buttons: [
          {
            title: that.$t('accept'),
            handler: () => {
              that.pendingRequest = true;
              //that.deleteElementProcess(element_id);
              that.$modal.hide('dialog');
            }
          },
          {
            title: that.$t('cancel')
          }
        ]
      });*/
    },

    toggleForward: function (forward) {
      console.log(forward);
      this.forward = forward;
      this.forward.enabled = !this.forward.enabled;
      console.log(this.forward);
      this.saveForward();
    },
  },

  watch: {
    '$route.params.name': function () {
      this.detectTableId();
    }
  },
};
</script>
