<template>
<div class="row">
    <div class="col-lg-12">
        <ul class="pt-4">
            <li>
                <strong>{{ $t('endpoint-add-one') }}:</strong> {{$t('endpoint-add-one-description')}}
                <pre class="pt-2 pb-2 text-xs">
curl -X POST \
http://localhost:3000/api/v1/tables/6/data/single \
-H 'Content-Type: application/json' \
-H 'Authorization: Bearer {{token}}' \
-d '{
    "data": {
        "id": 1,
        "name": "John Doe",
        "age": 30,
        "ip": "22.12.12.12"
    }
}'
                </pre>
            </li>
            <li>
                <strong>{{ $t('endpoint-add-multiple') }}:</strong> {{$t('endpoint-add-multiple-description')}}
                <pre class="pt-2 text-xs">
curl -X POST \
http://localhost:3000/api/v1/tables/6/data/multiple \
-H 'Content-Type: application/json' \
-H 'Authorization: Bearer {{token}}' \
-d '{
    "data": [
        {
            "id": 1,
            "name": "John Doe",
            "age": 30,
            "ip": "22.12.12.12"
        },
        {
            "id": 2,
            "name": "Jane Doe",
            "age": 25,
            "ip": "22.12.12.13"
        }
    ]
}'
                </pre>
            </li>
        </ul>
    </div>
</div>
</template>
<script>
 export default {
    name: 'source-api',
    data() {
        return {
            details: {
                
            }
        };
    },
    methods: {
        checkInput() {
            // this.$emit('input', this.details);
        }
    }
};
</script>
